import React from 'react';
import { InputNumber } from 'antd';
import KeyDataItem from './KeyDataItem';

export default function BookingSettings() {
  return (
    <>
      <KeyDataItem title="Accept booking within radius (miles)" k="maximunBookingRadius">
        <InputNumber
          min={0}
          formatter={value => `${value?.toLocaleString()}`}
          parser={value => value!.replace('', '')}
        />
      </KeyDataItem>
    </>
  );
}