import React from "react";
// import { Table, Select } from 'antd';
// import { useBookingsQuery, useUpdateBookingMutation, BookingStatus } from '../../apollo';

import { useHistory } from "react-router-dom";
import { useBookingsQuery, useDeleteBookingMutation } from "../../apollo";
import { useState } from "react";
import { PageHeader, Button, Modal, Table, Space } from "antd";
import { PlusOutlined, DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import BookingEdit from "../BookingEdit";

export default function PayoutBookingList() {
  const history = useHistory();
  const { loading, error, data, refetch } = useBookingsQuery({
    variables: {
      input: {
        keyword: "status=SUCCESS",
      },
    },
  });
  const [dele] = useDeleteBookingMutation();
  const [draft, setDraft] = useState<any>();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading...</p>;

  const records: Array<any> = (draft ? [draft] : []).concat(
    data?.bookings || []
  );

  const add = async () => {
    if (!draft) {
      setDraft({
        id: "draft",
        code: "LP-NEW",
        status: "CONNECTING",
      });
    }
  };

  const del = async (record: any) => {
    if (draft) {
      return setDraft(null);
    }
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Delete ${record.code}?`,
      onOk: async () => {
        await dele({
          variables: {
            id: record.id,
          },
        });
        refetch();
      },
    });
  };

  const saved = ({ id }: any) => {
    if (id === "draft") {
      setDraft(null);
      refetch();
    }
  };

  const downloadXLS = () => {
    window.open("https://backend.luxpro.app/booking/export-xls", "_blank");
  };

  const columns = [
    {
      title: "Payout",
      dataIndex: ["payoutStatus"],
    },
    {
      title: "Code",
      dataIndex: ["code"],
    },
    {
      title: "Status",
      dataIndex: ["status"],
    },
    {
      title: "Provider",
      dataIndex: ["provider", "email"],
    },
    {
      title: "Customer",
      dataIndex: ["customer", "email"],
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_: any, record: any) => (
    //     <Space>
    //       <Button onClick={() => del(record)} type="primary" danger>Delete</Button>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title="Payout Bookings"
        extra={[
          <Button
            key="1"
            onClick={downloadXLS}
            type="primary"
            icon={<DownloadOutlined />}
          >
            Export All Records
          </Button>,
        ]}
      />
      <Table
        rowKey="id"
        dataSource={records}
        columns={columns}
        expandable={{
          expandedRowRender: record => <BookingEdit record={record} onSaved={saved} />,
        }}
      />
    </>
  );
}
