import React from "react";
// import { Table, Select } from 'antd';
// import { useBookingsQuery, useUpdateBookingMutation, BookingStatus } from '../../apollo';

import { useHistory } from "react-router-dom";
import {
  BookingStatus,
  useBookingsQuery,
  useDeleteBookingMutation,
} from "../../apollo";
import { useState } from "react";
import { PageHeader, Button, Modal, Table, Space } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import BookingEdit from "../BookingEdit";
import PaymentModal from "./PaymentModal";
import Text from 'antd/lib/typography/Text';

// const BookingEdit = (props: any) => {
//   const { record } = props;
//   return <div>Booking Edit {record.id}</div>
// };

// const BookingList: SFC = () => {
//   const { loading, error, data } = useBookingsQuery({
//     variables: {
//       input: {}
//     }
//   });

//   const [updateBookingMutation] = useUpdateBookingMutation();

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error loading...</p>;

//   const { bookings } = data!;

//   const columns = [
//     {
//       title: 'ID',
//       dataIndex: 'id',
//     },
//     {
//       title: 'Status',
//       dataIndex: 'status',
//       render: (value: BookingStatus, record: any) => {
//         const handleChange = (nextValue: BookingStatus) => {
//           updateBookingMutation({
//             variables: {
//               id: record.id,
//               input: {
//                 status: nextValue
//               }
//             }
//           });
//         };
//         return (
//           <Select defaultValue={value} style={{ width: 150 }} onChange={handleChange}>
//             <Select.Option value="CONNECTING">CONNECTING</Select.Option>
//             <Select.Option value="WAITING">WAITING</Select.Option>
//             <Select.Option value="PROCESSING">PROCESSING</Select.Option>
//             <Select.Option value="COMPLETED">COMPLETED</Select.Option>
//             <Select.Option value="FAILED">FAILED</Select.Option>
//           </Select>
//         );
//       }
//     },
//     {
//       title: 'Provider',
//       dataIndex: ['provider', 'email'],
//     },
//     {
//       title: 'Customer',
//       dataIndex: ['customer', 'email'],
//     },

//   ];

//   return (
//     <Table
//       rowKey="id"
//       dataSource={bookings}
//       columns={columns}
//       expandable={{
//         expandedRowRender: record => <BookingEdit record={record} />,
//       }}
//     />
//   );
// };

// export default BookingList;
export default function BookingList() {
  const history = useHistory();
  const { loading, error, data, refetch } = useBookingsQuery({
    variables: {
      input: {},
    },
  });
  const [dele] = useDeleteBookingMutation();
  const [draft, setDraft] = useState<any>();
  const [paymentRecord, setPaymentRecord] = useState<any>();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading...</p>;

  const records: Array<any> = (draft ? [draft] : []).concat(
    data?.bookings || []
  );

  const add = async () => {
    if (!draft) {
      setDraft({
        id: "draft",
        code: "LP-NEW",
        status: "CONNECTING",
      });
    }
  };

  const del = async (record: any) => {
    if (draft) {
      return setDraft(null);
    }
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Delete ${record.code}?`,
      onOk: async () => {
        await dele({
          variables: {
            id: record.id,
          },
        });
        refetch();
      },
    });
  };

  const payment = (record: any) => {
    setPaymentRecord(record);
  };

  const saved = ({ id }: any) => {
    if (id === "draft") {
      setDraft(null);
      refetch();
    }
  };

  const columns = [
    {
      title: "Code",
      dataIndex: ["code"],
    },
    {
      title: "Status",
      dataIndex: ["status"],
      render: (_: any, record: any) => (
        <Text>{getBookingStatusText(record.status, true)}</Text>
      ),
    },
    {
      title: "Provider",
      dataIndex: ["provider", "email"],
    },
    {
      title: "Customer",
      dataIndex: ["customer", "email"],
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space>
          <Button onClick={() => payment(record)} type="primary">
            Payment
          </Button>
          <Button onClick={() => del(record)} type="primary" danger>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title="Bookings"
        // extra={[
        //   <Button key="1" onClick={add} type="primary" icon={<PlusOutlined />}>
        //     Add
        //   </Button>,
        // ]}
      />
      <Table
        rowKey="id"
        dataSource={records}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <BookingEdit record={record} onSaved={saved} />
          ),
        }}
      />
      <PaymentModal
        record={paymentRecord}
        onDone={() => setPaymentRecord(null)}
      />
    </>
  );
}

export function getBookingStatusText(
  status: BookingStatus,
  isShortCode = false
) {
  switch (status) {
    case BookingStatus.Connecting:
      return isShortCode ? "CONNECTING" : "Connecting Provider";
    case BookingStatus.Waiting:
      return isShortCode ? "CONFIRMED" : "Confirmed";
    case BookingStatus.Scheduled:
      return isShortCode ? "CONFIRMED" : "Confirmed";
    case BookingStatus.Completed:
      return isShortCode ? "SERVICED" : "Serviced";
    case BookingStatus.Success:
      return isShortCode ? "COMPLETED" : "Completed";
    case BookingStatus.Failed:
      return isShortCode ? "FAILED" : "Failed";
    default:
      return status;
  }
}
