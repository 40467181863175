import React, { useState } from 'react';
import { Table, PageHeader, Button, Modal, Space } from 'antd';
import { useProvidersQuery, useCreateServiceItemMutation, useDeleteServiceItemMutation, useSendAccountLinkMutation, useDeleteProviderMutation } from '../../apollo';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ServiceItemEdit from '../ServiceItemEdit';
import { useHistory } from 'react-router-dom';
import ProviderEdit from '../ProviderEdit';

export default function ProviderList() {
  const history = useHistory();
  const { loading, error, data, refetch } = useProvidersQuery({
    variables: {
      input: {}
    }
  });

  const [createServiceItem] = useCreateServiceItemMutation();
  const [dele] = useDeleteProviderMutation();
  const [sendAccountLink] = useSendAccountLinkMutation();
  const [accountLinkSending, setAccountLinkSending] = useState(null);
  const [draft, setDraft] = useState<any>();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading...</p>;

  const records: Array<any> = data?.providers || [];
  
  const add = async () => {
    if (!draft) {
      setDraft({
        id: 'draft',
      });
    }
  };

  const del = async (record: any) => {
    if (draft) {
      return setDraft(null);
    }
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Delete provider ${record.firstName} ${record.lastName}?`,
      onOk: async () => {
        await dele({
          variables: {
            id: record.id
          }
        });
        refetch();
      }
    });
  };

  const saved = ({ id }: any) => {
    if (id === 'draft') {
      setDraft(null);
      refetch();
    }
  };

  const sendAccount = async (record: any) => {
    setAccountLinkSending(record.id);
    await sendAccountLink({
      variables: {
        id: record.id
      }
    });
    refetch();
    setAccountLinkSending(null);
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: ['email'],
    },
    {
      title: 'Status',
      dataIndex: ['status'],
    },
    {
      title: 'FirstName',
      dataIndex: ['firstName'],
    },
    {
      title: 'LastName',
      dataIndex: ['lastName'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Space>
          {!record.stripeId &&
            <Button onClick={() => sendAccount(record)} type="primary" loading={accountLinkSending === record.id}>Send Account Link</Button>
          }
          {record.stripeId &&
            <Button onClick={() => sendAccount(record)} type="primary" loading={accountLinkSending === record.id}>Resend Account Link</Button>
          }
          <Button onClick={() => del(record)} type="primary" danger>Delete</Button>
        </Space>
      ),
    },
  ];
  
  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title="Providers"
        extra={[
          // <Button key="1" onClick={add} type="primary" icon={<PlusOutlined />}>
          //   Add
          // </Button>,
        ]}
      />
      <Table
        rowKey="id"
        dataSource={records}
        columns={columns}
        expandable={{
          expandedRowRender: record => <ProviderEdit record={record} onSaved={saved} />,
        }}
      />
    </>
  );
}