import React from 'react';
import { Button, Form, message, Input, DatePicker, Row, Col, Space } from 'antd';
import { useUpdateCustomerMutation, useCreateCustomerMutation, useCustomerQuery } from '../../apollo';
import { BookingStatus } from '../../types';
import { pickByTypeName } from '../../shared/utils';
import moment from 'moment';
import InputUpload from '../../shared/InputUpload';
import { pick } from 'lodash';

export default function CustomerEdit({ record, onSaved }: { record: any, onSaved: (record: any) => void }) {
  const isDraft = record.id === 'draft';
  const { data } = useCustomerQuery({
    variables: {
      id: record.id
    },
    skip: isDraft
  });

  const [update, { loading: updating }] = useUpdateCustomerMutation();
  const [create, { loading: creating }] = useCreateCustomerMutation();

  const [form] = Form.useForm();
  const initialValues = isDraft ? record : (data ? {
    ...data.customer,
    birthday: data.customer?.birthday ? moment(data.customer?.birthday) : undefined,
    // customer: data.booking.customer.id,
    // provider: (data.booking.provider || {}).id,
    // serviceItems: data.booking.serviceItems.map(({ id }) => id),
    // colors: (data.booking.colors || []).map(({ id }) => id)
    avatar: data.customer?.avatar ? pick(data.customer?.avatar, ['filename', 'title']) : undefined
  } : null);
  if (!initialValues) {
    return null;
  }
  const isSubmitting = creating || updating;

  const onFinish = async (values: any) => {
    // const serviceItem = serviceItemsData?.serviceItems?.find(({ id }) => id === values.serviceItems[0]);
    // values = {
    //   type: BookingType.Now,
    //   status: BookingStatus.Connecting,
    //   serviceItems: [],
    //   location: {
    //     name: 'Sample Address',
    //     longitude: 105.77448959999992, 
    //     latitude: 10.0247128,
    //   },
    //   category: serviceItem?.categories[0],
    //   ...values
    // }

    // if (values.provider && providersData) {
    //   values.provider = providersData.providers!.find(({ id }) => id === values.provider);
    // }

    // if (values.customer && customersData) {
    //   values.customer = customersData.customers!.find(({ id }) => id === values.customer);
    // }

    // if (values.serviceItems && serviceItemsData) {
    //   values.serviceItems = values.serviceItems.map((itemId: string) => {
    //     return serviceItemsData.serviceItems!.find(({ id }) => id === itemId);
    //   }).map((serviceItem: ServiceItemFragment) => ({
    //     ...serviceItem,
    //     quantity: serviceItem.quantity ?? 1,
    //   }));
    // }

    // if (values.colors && colorsData) {
    //   values.colors = values.colors.map((itemId: string) => {
    //     return colorsData.colors!.find(({ id }) => id === itemId);
    //   });
    // }

    // values = pickByTypeName(values, {
    //   Provider: ['id', 'email', 'status', 'firstName', 'lastName', 'phoneNumber', 'gender', 'birthday', 'address', 'uploads', 'bio', 'facebook', 'viber', 'zalo', 'bankAccount'],
    //   Customer: ['id', 'email', 'status', 'firstName', 'lastName', 'phoneNumber', 'gender', 'address', 'birthday'],
    //   ServiceItem: [ 'id', 'categories', 'title', 'description', 'unitPrice', 'mins', 'photos', 'quantity'],
    //   Color: [ 'id', 'categories', 'title', 'description', 'photos',],
    //   Media: ['title', 'filename'],
    //   Address: ['country', 'street', 'city', 'zip', 'county', 'province', 'state',]
    // });

    const { errors } = await (() => {
      if (isDraft) {
        return create({
          variables: {
            input: values
          }
        });
      }
      delete values.email;
      return update({
        variables: {
          id: record.id,
          input: values
        }
      });
    })()

    if (!errors) {
      message.success('Updated');
      return onSaved(record);
    }

  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >

      <Form.Item
        name="avatar"
        label="Avatar"
      >
        <InputUpload />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        required={true}
        rules={[{ required: true }]}
      >
        <Input readOnly={!isDraft} disabled={!isDraft} />
      </Form.Item>

      <Form.Item
        name="firstName"
        label="First Name"
        required={true}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        required={true}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        required={isDraft}
        rules={[{ required: isDraft }]}
        extra={isDraft ? '' : 'If want to change password'}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="phoneNumber"
        label="Telephone"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="birthday"
        label="Birthday"
        rules={[{ type: 'object' }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item label="Address">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'street']} noStyle>
                <Input placeholder="Street" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={['address', 'city']} noStyle>
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['address', 'zip']} noStyle>
                <Input placeholder="Zip" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={['address', 'county']} noStyle>
                <Input placeholder="County" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['address', 'state']} noStyle>
                <Input placeholder="State" />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form.Item>

      <Form.Item
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  
}