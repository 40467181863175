import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Activity = {
  __typename?: "Activity";
  id: Scalars["ID"];
  user: Scalars["ID"];
  action: ActivityAction;
  refType: ActivityType;
  refId: Scalars["ID"];
};

export enum ActivityAction {
  View = "VIEW",
  Favorite = "FAVORITE",
}

export type ActivityStats = {
  __typename?: "ActivityStats";
  refId?: Maybe<Scalars["ID"]>;
  refType?: Maybe<ActivityType>;
  isFavorite?: Maybe<Scalars["Boolean"]>;
  totalFavorite?: Maybe<Scalars["Int"]>;
};

export enum ActivityType {
  User = "USER",
  ServiceItem = "SERVICE_ITEM",
}

export type AddFavoriteInput = {
  refType: ActivityType;
  refId: Scalars["ID"];
};

export type Address = {
  __typename?: "Address";
  country?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  country?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type App = {
  __typename?: "App";
  bookingFeePercent: Scalars["Float"];
  minimumBookingAmount: Scalars["Float"];
  maximunBookingRadius: Scalars["Float"];
};

export type BankAccount = {
  __typename?: "BankAccount";
  ssntin?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  address?: Maybe<Address>;
  currency?: Maybe<Scalars["String"]>;
  routingNumber?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
};

export type BankAccountInput = {
  ssntin?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressInput>;
  currency?: Maybe<Scalars["String"]>;
  routingNumber?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
};

/** Booking */
export type Booking = {
  __typename?: "Booking";
  id: Scalars["ID"];
  code: Scalars["String"];
  category: Scalars["String"];
  /**
   * NOW: process immediately
   * SCHEDULE: process on sheduled date
   */
  type: BookingType;
  /**
   * CONNECTING: Seeking for provider
   * SCHEDULED: Schedule confirmed only for BookingType = Schedule
   * WAITING: Waiting for provider come
   * PROCESSING: Service processing
   * COMPLETED: Service completed
   * FAILED: Booking failed, can happen on any steps
   */
  status: BookingStatus;
  scheduleDate?: Maybe<Scalars["DateTime"]>;
  provider?: Maybe<Provider>;
  customer: Customer;
  serviceItems: Array<Scalars["JSON"]>;
  colors?: Maybe<Array<Scalars["JSON"]>>;
  payment?: Maybe<Payment>;
  location: Location;
  phoneNumber?: Maybe<Scalars["String"]>;
  favouritePriority?: Maybe<Scalars["Boolean"]>;
  emailNotify?: Maybe<Scalars["Boolean"]>;
  textNotify?: Maybe<Scalars["Boolean"]>;
  specialRequest?: Maybe<Scalars["String"]>;
  createdOn: Scalars["DateTime"];
};

export type BookingColorInput = {
  id?: Maybe<Scalars["ID"]>;
  categories?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  photos?: Maybe<Array<Maybe<MediaInput>>>;
};

export type BookingCustomerInput = {
  id?: Maybe<Scalars["ID"]>;
  email: Scalars["String"];
  status?: Maybe<CustomerStatus>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
  birthday?: Maybe<Scalars["DateTime"]>;
  favouriteServiceItems: Array<Scalars["ID"]>;
  favouriteColors: Array<Scalars["ID"]>;
};

export type BookingProviderInput = {
  id?: Maybe<Scalars["ID"]>;
  email: Scalars["String"];
  status?: Maybe<ProviderStatus>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  birthday?: Maybe<Scalars["DateTime"]>;
  serviceTypes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  address?: Maybe<AddressInput>;
  uploads?: Maybe<Array<Maybe<MediaInput>>>;
  bio?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  viber?: Maybe<Scalars["String"]>;
  zalo?: Maybe<Scalars["String"]>;
  bankAccount?: Maybe<BankAccountInput>;
};

export type BookingServiceItemInput = {
  id?: Maybe<Scalars["ID"]>;
  categories?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  unitPrice?: Maybe<Scalars["Float"]>;
  mins?: Maybe<Scalars["Int"]>;
  photos?: Maybe<Array<Maybe<MediaInput>>>;
  quantity: Scalars["Int"];
};

export enum BookingStatus {
  Connecting = "CONNECTING",
  Scheduled = "SCHEDULED",
  Waiting = "WAITING",
  Processing = "PROCESSING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Success = "SUCCESS",
}

export enum BookingType {
  Now = "NOW",
  Schedule = "SCHEDULE",
}

export enum CacheControlScope {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type Card = {
  __typename?: "Card";
  id: Scalars["ID"];
  brand: Scalars["String"];
  last4: Scalars["String"];
};

export type Color = {
  __typename?: "Color";
  id: Scalars["ID"];
  categories: Array<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  photos: Array<Media>;
  colorCategories: Array<ColorCategory>;
  sort?: Maybe<Scalars["Int"]>;
};

export type ColorCategory = {
  __typename?: "ColorCategory";
  id: Scalars["ID"];
  parent?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
  colors?: Maybe<Array<Color>>;
};

export type CreateBookingInput = {
  type: BookingType;
  category: Scalars["String"];
  status: BookingStatus;
  scheduleDate?: Maybe<Scalars["DateTime"]>;
  provider?: Maybe<BookingProviderInput>;
  customer: BookingCustomerInput;
  serviceItems?: Maybe<Array<BookingServiceItemInput>>;
  colors?: Maybe<Array<Maybe<BookingColorInput>>>;
  location: LocationInput;
  favoritePriority?: Maybe<Scalars["Boolean"]>;
  emailNotify?: Maybe<Scalars["Boolean"]>;
  textNotify?: Maybe<Scalars["Boolean"]>;
  specialRequest?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type CreateCardInput = {
  token: Scalars["String"];
  brand: Scalars["String"];
  last4: Scalars["String"];
};

export type CreateColorCategoryInput = {
  parent?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
};

export type CreateColorInput = {
  title?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
  photos?: Maybe<Array<Maybe<MediaInput>>>;
};

export type CreateCustomerInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type CreateProviderInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type CreateServiceCategoryInput = {
  parent?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
};

export type CreateServiceItemInput = {
  title?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  description?: Maybe<Scalars["String"]>;
  unitPrice?: Maybe<Scalars["Float"]>;
  mins?: Maybe<Scalars["Int"]>;
  photos?: Maybe<Array<Maybe<MediaInput>>>;
};

export type CreateUserInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type Customer = {
  __typename?: "Customer";
  id: Scalars["ID"];
  email: Scalars["String"];
  status: CustomerStatus;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  birthday?: Maybe<Scalars["DateTime"]>;
  avatar?: Maybe<Media>;
  favouriteServiceItems: Array<Scalars["ID"]>;
  favouriteColors: Array<Scalars["ID"]>;
  emailNotify?: Maybe<Scalars["Boolean"]>;
  textNotify?: Maybe<Scalars["Boolean"]>;
  promotionNotify?: Maybe<Scalars["Boolean"]>;
  isTester?: Maybe<Scalars["Boolean"]>;
};

export enum CustomerStatus {
  Activated = "ACTIVATED",
  Deactivated = "DEACTIVATED",
}

export type Discount = {
  __typename?: "Discount";
  id: Scalars["ID"];
  user: Scalars["ID"];
  amount: Scalars["Float"];
  percent: Scalars["Float"];
  type: DiscountType;
  usedOn?: Maybe<Scalars["DateTime"]>;
  expiredOn?: Maybe<Scalars["DateTime"]>;
};

export enum DiscountType {
  Promotion = "PROMOTION",
  Referrer = "REFERRER",
  Referred = "REFERRED",
  Voucher = "VOUCHER",
}

export type File = {
  __typename?: "File";
  filename?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  attrs?: Maybe<Scalars["JSON"]>;
  createdOn?: Maybe<Scalars["DateTime"]>;
  uri?: Maybe<Scalars["String"]>;
};

export enum Gender {
  Male = "MALE",
  Female = "FEMALE",
  Other = "OTHER",
}

export type Location = {
  __typename?: "Location";
  name: Scalars["String"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type LocationInput = {
  name: Scalars["String"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type LoginUserInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type Media = {
  __typename?: "Media";
  id: Scalars["ID"];
  filename?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  attrs?: Maybe<Scalars["JSON"]>;
  uri?: Maybe<Scalars["String"]>;
  createdOn?: Maybe<Scalars["DateTime"]>;
};

export type MediaInput = {
  filename: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  root?: Maybe<Scalars["String"]>;
  createUser?: Maybe<User>;
  loginUser?: Maybe<UserToken>;
  addToken?: Maybe<User>;
  removeToken?: Maybe<User>;
  createProvider?: Maybe<Provider>;
  updateProvider?: Maybe<Provider>;
  deleteProvider?: Maybe<Scalars["Int"]>;
  loginProvider?: Maybe<UserToken>;
  sendAccountLink?: Maybe<Scalars["Boolean"]>;
  createCustomer?: Maybe<Customer>;
  updateCustomer?: Maybe<Customer>;
  deleteCustomer?: Maybe<Scalars["Int"]>;
  loginCustomer?: Maybe<UserToken>;
  addFavouriteServiceItem?: Maybe<Customer>;
  removeFavouriteServiceItem?: Maybe<Customer>;
  addFavouriteColor?: Maybe<Customer>;
  removeFavouriteColor?: Maybe<Customer>;
  createServiceCategory?: Maybe<ServiceCategory>;
  updateServiceCategory?: Maybe<ServiceCategory>;
  deleteServiceCategory?: Maybe<Scalars["Int"]>;
  createServiceItem?: Maybe<ServiceItem>;
  deleteServiceItem?: Maybe<Scalars["Int"]>;
  updateServiceItem?: Maybe<ServiceItem>;
  createColor?: Maybe<Color>;
  deleteColor?: Maybe<Scalars["Int"]>;
  updateColor?: Maybe<Color>;
  createColorCategory?: Maybe<ColorCategory>;
  updateColorCategory?: Maybe<ColorCategory>;
  deleteColorCategory?: Maybe<Scalars["Int"]>;
  updatePayment?: Maybe<Payment>;
  requestPayment?: Maybe<Payment>;
  createPaymentIntent?: Maybe<Scalars["String"]>;
  createBooking?: Maybe<Booking>;
  deleteBooking?: Maybe<Scalars["Int"]>;
  updateBooking?: Maybe<Booking>;
  payBooking?: Maybe<Booking>;
  createCard?: Maybe<Card>;
  deleteCard?: Maybe<Scalars["Boolean"]>;
  markRead?: Maybe<Notification>;
  singleUpload: File;
  addFavorite: Scalars["Boolean"];
  removeFavorite: Scalars["Boolean"];
  setKey?: Maybe<Scalars["JSON"]>;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

export type MutationAddTokenArgs = {
  token: Scalars["String"];
};

export type MutationRemoveTokenArgs = {
  token: Scalars["String"];
};

export type MutationCreateProviderArgs = {
  input: CreateProviderInput;
};

export type MutationUpdateProviderArgs = {
  id: Scalars["ID"];
  input: UpdateProviderInput;
};

export type MutationDeleteProviderArgs = {
  id: Scalars["ID"];
};

export type MutationLoginProviderArgs = {
  input: LoginUserInput;
};

export type MutationSendAccountLinkArgs = {
  id: Scalars["ID"];
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationUpdateCustomerArgs = {
  id: Scalars["ID"];
  input: UpdateCustomerInput;
};

export type MutationDeleteCustomerArgs = {
  id: Scalars["ID"];
};

export type MutationLoginCustomerArgs = {
  input: LoginUserInput;
};

export type MutationAddFavouriteServiceItemArgs = {
  id: Scalars["String"];
};

export type MutationRemoveFavouriteServiceItemArgs = {
  id: Scalars["String"];
};

export type MutationAddFavouriteColorArgs = {
  id: Scalars["String"];
};

export type MutationRemoveFavouriteColorArgs = {
  id: Scalars["String"];
};

export type MutationCreateServiceCategoryArgs = {
  input: CreateServiceCategoryInput;
};

export type MutationUpdateServiceCategoryArgs = {
  id: Scalars["ID"];
  input: UpdateServiceCategoryInput;
};

export type MutationDeleteServiceCategoryArgs = {
  id: Scalars["ID"];
};

export type MutationCreateServiceItemArgs = {
  input: CreateServiceItemInput;
};

export type MutationDeleteServiceItemArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateServiceItemArgs = {
  id: Scalars["ID"];
  input: UpdateServiceItemInput;
};

export type MutationCreateColorArgs = {
  input: CreateColorInput;
};

export type MutationDeleteColorArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateColorArgs = {
  id: Scalars["ID"];
  input: UpdateColorInput;
};

export type MutationCreateColorCategoryArgs = {
  input: CreateColorCategoryInput;
};

export type MutationUpdateColorCategoryArgs = {
  id: Scalars["ID"];
  input: UpdateColorCategoryInput;
};

export type MutationDeleteColorCategoryArgs = {
  id: Scalars["ID"];
};

export type MutationUpdatePaymentArgs = {
  id: Scalars["ID"];
  input: UpdatePaymentInput;
};

export type MutationRequestPaymentArgs = {
  id: Scalars["ID"];
};

export type MutationCreatePaymentIntentArgs = {
  id: Scalars["ID"];
  testMode?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateBookingArgs = {
  input: CreateBookingInput;
};

export type MutationDeleteBookingArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateBookingArgs = {
  id: Scalars["ID"];
  input: UpdateBookingInput;
};

export type MutationPayBookingArgs = {
  id: Scalars["ID"];
  input: PayBookingInput;
};

export type MutationCreateCardArgs = {
  input: CreateCardInput;
};

export type MutationDeleteCardArgs = {
  id: Scalars["ID"];
};

export type MutationMarkReadArgs = {
  id: Scalars["ID"];
};

export type MutationSingleUploadArgs = {
  file: Scalars["Upload"];
};

export type MutationAddFavoriteArgs = {
  input: AddFavoriteInput;
};

export type MutationRemoveFavoriteArgs = {
  input: RemoveFavoriteInput;
};

export type MutationSetKeyArgs = {
  key: Scalars["String"];
  data?: Maybe<Scalars["JSON"]>;
};

export type Notification = {
  __typename?: "Notification";
  id: Scalars["ID"];
  to: Scalars["ID"];
  type: NotificationType;
  refType?: Maybe<NotificationRefType>;
  refId?: Maybe<Scalars["ID"]>;
  title: Scalars["String"];
  content: Scalars["String"];
  read: Scalars["Boolean"];
  createdOn: Scalars["DateTime"];
};

export enum NotificationRefType {
  Booking = "BOOKING",
}

export enum NotificationType {
  Notification = "NOTIFICATION",
  Alert = "ALERT",
}

export type PayBookingInput = {
  cardId?: Maybe<Scalars["ID"]>;
};

export type Payment = {
  __typename?: "Payment";
  id: Scalars["ID"];
  status: PaymentStatus;
  type: PaymentType;
  value: PaymentValue;
  discount?: Maybe<Discount>;
  booking: Booking;
  createdOn: Scalars["DateTime"];
  updatedOn: Scalars["DateTime"];
};

export enum PaymentStatus {
  Pending = "PENDING",
  Failed = "FAILED",
  Completed = "COMPLETED",
}

export enum PaymentType {
  Cash = "CASH",
  Card = "CARD",
}

export type PaymentValue = {
  __typename?: "PaymentValue";
  price: Scalars["Float"];
  vat: Scalars["Float"];
  serviceFee: Scalars["Float"];
  processingFee: Scalars["Float"];
  discount: Scalars["Float"];
  total: Scalars["Float"];
  tip: Scalars["Float"];
};

export type Provider = {
  __typename?: "Provider";
  id: Scalars["ID"];
  email: Scalars["String"];
  status: ProviderStatus;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  birthday?: Maybe<Scalars["DateTime"]>;
  serviceTypes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  address?: Maybe<Address>;
  uploads?: Maybe<Array<Maybe<Media>>>;
  bio?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  viber?: Maybe<Scalars["String"]>;
  zalo?: Maybe<Scalars["String"]>;
  bankAccount?: Maybe<BankAccount>;
  avatar?: Maybe<Media>;
  available?: Maybe<Scalars["Boolean"]>;
  autoAccept?: Maybe<Scalars["Boolean"]>;
  providerId?: Maybe<Media>;
  governmentId?: Maybe<Media>;
  driverLicense?: Maybe<Media>;
  serviceLicense?: Maybe<Media>;
  additionalLicense?: Maybe<Media>;
  additionalCertificate?: Maybe<Media>;
  feature?: Maybe<Media>;
  feature2?: Maybe<Media>;
  feature3?: Maybe<Media>;
  emailNotify?: Maybe<Scalars["Boolean"]>;
  textNotify?: Maybe<Scalars["Boolean"]>;
  promotionNotify?: Maybe<Scalars["Boolean"]>;
  isTester?: Maybe<Scalars["Boolean"]>;
  stripeId?: Maybe<Scalars["String"]>;
};

export enum ProviderStatus {
  Activated = "ACTIVATED",
  Deactivated = "DEACTIVATED",
  Reviewing = "REVIEWING",
}

export type Query = {
  __typename?: "Query";
  root?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  authUser?: Maybe<User>;
  provider?: Maybe<Provider>;
  providers?: Maybe<Array<Provider>>;
  authProvider?: Maybe<Provider>;
  customer?: Maybe<Customer>;
  customers?: Maybe<Array<Customer>>;
  authCustomer?: Maybe<Customer>;
  serviceCategory?: Maybe<ServiceCategory>;
  serviceCategories?: Maybe<Array<ServiceCategory>>;
  serviceItem?: Maybe<ServiceItem>;
  serviceItems?: Maybe<Array<ServiceItem>>;
  color?: Maybe<Color>;
  colors?: Maybe<Array<Color>>;
  colorCategory?: Maybe<ColorCategory>;
  colorCategories?: Maybe<Array<ColorCategory>>;
  payment?: Maybe<Payment>;
  payments?: Maybe<Array<Payment>>;
  booking: Booking;
  bookings: Array<Booking>;
  currentBooking?: Maybe<Booking>;
  cards: Array<Card>;
  notifications: Array<Notification>;
  notificationTotal: Scalars["Int"];
  getStats?: Maybe<Array<ActivityStats>>;
  myFavoriteServiceItems?: Maybe<Array<ServiceItem>>;
  app: App;
  getKey?: Maybe<Scalars["JSON"]>;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryProviderArgs = {
  id: Scalars["ID"];
};

export type QueryProvidersArgs = {
  input?: Maybe<SearchProviderInput>;
};

export type QueryCustomerArgs = {
  id: Scalars["ID"];
};

export type QueryCustomersArgs = {
  input?: Maybe<SearchCustomerInput>;
};

export type QueryServiceCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryServiceCategoriesArgs = {
  input?: Maybe<SearchServiceCategoryInput>;
};

export type QueryServiceItemArgs = {
  id: Scalars["ID"];
};

export type QueryServiceItemsArgs = {
  input?: Maybe<SearchServiceItemInput>;
};

export type QueryColorArgs = {
  id: Scalars["ID"];
};

export type QueryColorsArgs = {
  input?: Maybe<SearchColorInput>;
};

export type QueryColorCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryColorCategoriesArgs = {
  input?: Maybe<SearchColorCategoryInput>;
};

export type QueryPaymentArgs = {
  id: Scalars["ID"];
};

export type QueryPaymentsArgs = {
  input?: Maybe<SearchPaymentInput>;
};

export type QueryBookingArgs = {
  id: Scalars["ID"];
};

export type QueryBookingsArgs = {
  input?: Maybe<SearchBookingInput>;
};

export type QueryNotificationsArgs = {
  input?: Maybe<SearchNotificationInput>;
};

export type QueryNotificationTotalArgs = {
  type?: Maybe<NotificationType>;
};

export type QueryGetStatsArgs = {
  input: SearchActivityInput;
};

export type QueryGetKeyArgs = {
  key: Scalars["String"];
};

export type RemoveFavoriteInput = {
  refType: ActivityType;
  refId: Scalars["ID"];
};

export type SearchActivityInput = {
  refType: ActivityType;
  refIds?: Maybe<Array<Scalars["ID"]>>;
};

export type SearchBookingInput = {
  keyword?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type SearchColorCategoryInput = {
  keyword?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["JSON"]>;
};

export type SearchColorInput = {
  keyword?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["JSON"]>;
};

export type SearchCustomerInput = {
  keyword?: Maybe<Scalars["String"]>;
};

export type SearchNotificationInput = {
  type: NotificationType;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type SearchPaymentInput = {
  keyword?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type SearchProviderInput = {
  keyword?: Maybe<Scalars["String"]>;
};

export type SearchServiceCategoryInput = {
  keyword?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["JSON"]>;
};

export type SearchServiceItemInput = {
  keyword?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["JSON"]>;
};

export type ServiceCategory = {
  __typename?: "ServiceCategory";
  id: Scalars["ID"];
  parent?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
  serviceItems?: Maybe<Array<ServiceItem>>;
};

export type ServiceItem = {
  __typename?: "ServiceItem";
  id: Scalars["ID"];
  categories: Array<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  unitPrice?: Maybe<Scalars["Float"]>;
  mins?: Maybe<Scalars["Int"]>;
  photos: Array<Media>;
  serviceCategories: Array<ServiceCategory>;
  quantity?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  root?: Maybe<Scalars["String"]>;
  /** Subscribe a booking updated event */
  bookingUpdated?: Maybe<Booking>;
  /**
   * Subscribe booking connecting for provider
   * Notes: booking still not assign for any provider at this time
   */
  providerBooking?: Maybe<Booking>;
};

export type SubscriptionBookingUpdatedArgs = {
  id: Scalars["ID"];
};

export type SubscriptionProviderBookingArgs = {
  providerId: Scalars["ID"];
};

export type UpdateBookingInput = {
  type?: Maybe<BookingType>;
  category?: Maybe<Scalars["String"]>;
  status?: Maybe<BookingStatus>;
  scheduleDate?: Maybe<Scalars["DateTime"]>;
  provider?: Maybe<BookingProviderInput>;
  customer?: Maybe<BookingCustomerInput>;
  serviceItems?: Maybe<Array<BookingServiceItemInput>>;
  colors?: Maybe<Array<Maybe<BookingColorInput>>>;
  location?: Maybe<LocationInput>;
  favoritePriority?: Maybe<Scalars["Boolean"]>;
  emailNotify?: Maybe<Scalars["Boolean"]>;
  textNotify?: Maybe<Scalars["Boolean"]>;
};

export type UpdateColorCategoryInput = {
  parent?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
};

export type UpdateColorInput = {
  title?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
  photos?: Maybe<Array<Maybe<MediaInput>>>;
};

export type UpdateCustomerInput = {
  password?: Maybe<Scalars["String"]>;
  status?: Maybe<ProviderStatus>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
  birthday?: Maybe<Scalars["DateTime"]>;
  avatar?: Maybe<MediaInput>;
  emailNotify?: Maybe<Scalars["Boolean"]>;
  textNotify?: Maybe<Scalars["Boolean"]>;
  promotionNotify?: Maybe<Scalars["Boolean"]>;
};

export type UpdatePaymentInput = {
  status?: Maybe<PaymentStatus>;
  type?: Maybe<PaymentType>;
  tip?: Maybe<Scalars["Float"]>;
  cardId?: Maybe<Scalars["ID"]>;
};

export type UpdateProviderInput = {
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  status?: Maybe<ProviderStatus>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  birthday?: Maybe<Scalars["DateTime"]>;
  serviceTypes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  address?: Maybe<AddressInput>;
  uploads?: Maybe<Array<Maybe<MediaInput>>>;
  bio?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  viber?: Maybe<Scalars["String"]>;
  zalo?: Maybe<Scalars["String"]>;
  bankAccount?: Maybe<BankAccountInput>;
  avatar?: Maybe<MediaInput>;
  available?: Maybe<Scalars["Boolean"]>;
  autoAccept?: Maybe<Scalars["Boolean"]>;
  providerId?: Maybe<MediaInput>;
  governmentId?: Maybe<MediaInput>;
  driverLicense?: Maybe<MediaInput>;
  serviceLicense?: Maybe<MediaInput>;
  additionalLicense?: Maybe<MediaInput>;
  additionalCertificate?: Maybe<MediaInput>;
  feature?: Maybe<MediaInput>;
  feature2?: Maybe<MediaInput>;
  feature3?: Maybe<MediaInput>;
  emailNotify?: Maybe<Scalars["Boolean"]>;
  textNotify?: Maybe<Scalars["Boolean"]>;
  promotionNotify?: Maybe<Scalars["Boolean"]>;
};

export type UpdateServiceCategoryInput = {
  parent?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["Int"]>;
};

export type UpdateServiceItemInput = {
  title?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  description?: Maybe<Scalars["String"]>;
  unitPrice?: Maybe<Scalars["Float"]>;
  mins?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["Int"]>;
  photos?: Maybe<Array<Maybe<MediaInput>>>;
};

export type User = {
  __typename?: "User";
  id?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
};

export enum UserRealm {
  User = "USER",
  Provider = "PROVIDER",
  Customer = "CUSTOMER",
}

export type UserToken = {
  __typename?: "UserToken";
  accessToken?: Maybe<Scalars["String"]>;
  realm?: Maybe<UserRealm>;
};

export const AppFragmentDoc = gql`
  fragment App on App {
    bookingFeePercent
  }
`;
export const MediaFragmentDoc = gql`
  fragment Media on Media {
    filename
    title
    attrs
    createdOn
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    country
    street
    city
    zip
    county
    province
    state
  }
`;
export const BankAccountFragmentDoc = gql`
  fragment BankAccount on BankAccount {
    ssntin
    businessName
    address {
      ...Address
    }
    currency
    routingNumber
    accountNumber
  }
  ${AddressFragmentDoc}
`;
export const ProviderFragmentDoc = gql`
  fragment Provider on Provider {
    id
    email
    status
    firstName
    lastName
    phoneNumber
    gender
    birthday
    serviceTypes
    avatar {
      ...Media
    }
    address {
      ...Address
    }
    uploads {
      ...Media
    }
    providerId {
      ...Media
    }
    governmentId {
      ...Media
    }
    driverLicense {
      ...Media
    }
    serviceLicense {
      ...Media
    }
    additionalLicense {
      ...Media
    }
    additionalCertificate {
      ...Media
    }
    feature {
      ...Media
    }
    feature2 {
      ...Media
    }
    feature3 {
      ...Media
    }
    bio
    facebook
    viber
    zalo
    bankAccount {
      ...BankAccount
    }
    stripeId
  }
  ${MediaFragmentDoc}
  ${AddressFragmentDoc}
  ${BankAccountFragmentDoc}
`;
export const CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    id
    email
    status
    firstName
    lastName
    phoneNumber
    gender
    avatar {
      ...Media
    }
    address {
      ...Address
    }
    birthday
    favouriteServiceItems
    favouriteColors
  }
  ${MediaFragmentDoc}
  ${AddressFragmentDoc}
`;
export const BookingFragmentDoc = gql`
  fragment Booking on Booking {
    id
    code
    type
    status
    scheduleDate
    provider {
      ...Provider
    }
    customer {
      ...Customer
    }
    serviceItems
    colors
    location {
      name
      latitude
      longitude
    }
    payoutStatus
  }
  ${ProviderFragmentDoc}
  ${CustomerFragmentDoc}
`;
export const PaymentValueFragmentDoc = gql`
  fragment PaymentValue on PaymentValue {
    price
    vat
    serviceFee
    processingFee
    discount
    total
    tip
  }
`;
export const DiscountFragmentDoc = gql`
  fragment Discount on Discount {
    id
    user
    amount
    percent
    type
    usedOn
    expiredOn
  }
`;
export const PaymentFragmentDoc = gql`
  fragment Payment on Payment {
    id
    type
    status
    type
    value {
      ...PaymentValue
    }
    discount {
      ...Discount
    }
    booking {
      ...Booking
    }
    createdOn
    updatedOn
  }
  ${PaymentValueFragmentDoc}
  ${DiscountFragmentDoc}
  ${BookingFragmentDoc}
`;
export const BookingDetailFragmentDoc = gql`
  fragment BookingDetail on Booking {
    ...Booking
    payment {
      ...Payment
    }
  }
  ${BookingFragmentDoc}
  ${PaymentFragmentDoc}
`;
export const CardFragmentDoc = gql`
  fragment Card on Card {
    id
    brand
    last4
  }
`;
export const ColorCategoryFragmentDoc = gql`
  fragment ColorCategory on ColorCategory {
    id
    title
    parent
    description
    sort
  }
`;
export const ColorFragmentDoc = gql`
  fragment Color on Color {
    id
    categories
    title
    description
    sort
    photos {
      ...Media
    }
    colorCategories {
      ...ColorCategory
    }
  }
  ${MediaFragmentDoc}
  ${ColorCategoryFragmentDoc}
`;
export const LocationFragmentDoc = gql`
  fragment Location on Location {
    name
    latitude
    longitude
  }
`;
export const ServiceCategoryFragmentDoc = gql`
  fragment ServiceCategory on ServiceCategory {
    id
    title
    parent
    description
    sort
  }
`;
export const ServiceItemFragmentDoc = gql`
  fragment ServiceItem on ServiceItem {
    id
    mins
    categories
    title
    description
    unitPrice
    quantity
    sort
    photos {
      ...Media
    }
    serviceCategories {
      ...ServiceCategory
    }
  }
  ${MediaFragmentDoc}
  ${ServiceCategoryFragmentDoc}
`;
export const AppDocument = gql`
  query app {
    app {
      ...App
    }
  }
  ${AppFragmentDoc}
`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AppQuery, AppQueryVariables>
) {
  return ApolloReactHooks.useQuery<AppQuery, AppQueryVariables>(
    AppDocument,
    baseOptions
  );
}
export function useAppLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AppQuery,
    AppQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AppQuery, AppQueryVariables>(
    AppDocument,
    baseOptions
  );
}
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = ApolloReactCommon.QueryResult<
  AppQuery,
  AppQueryVariables
>;
export const BookingDocument = gql`
  query booking($id: ID!) {
    booking(id: $id) {
      ...BookingDetail
    }
  }
  ${BookingDetailFragmentDoc}
`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BookingQuery,
    BookingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<BookingQuery, BookingQueryVariables>(
    BookingDocument,
    baseOptions
  );
}
export function useBookingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BookingQuery,
    BookingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<BookingQuery, BookingQueryVariables>(
    BookingDocument,
    baseOptions
  );
}
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = ApolloReactCommon.QueryResult<
  BookingQuery,
  BookingQueryVariables
>;
export const BookingsDocument = gql`
  query bookings($input: SearchBookingInput!) {
    bookings(input: $input) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BookingsQuery,
    BookingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<BookingsQuery, BookingsQueryVariables>(
    BookingsDocument,
    baseOptions
  );
}
export function useBookingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BookingsQuery,
    BookingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<BookingsQuery, BookingsQueryVariables>(
    BookingsDocument,
    baseOptions
  );
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<
  typeof useBookingsLazyQuery
>;
export type BookingsQueryResult = ApolloReactCommon.QueryResult<
  BookingsQuery,
  BookingsQueryVariables
>;
export const UpdateBookingDocument = gql`
  mutation updateBooking($id: ID!, $input: UpdateBookingInput!) {
    updateBooking(id: $id, input: $input) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export type UpdateBookingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBookingMutation,
  UpdateBookingMutationVariables
>;

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBookingMutation,
    UpdateBookingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateBookingMutation,
    UpdateBookingMutationVariables
  >(UpdateBookingDocument, baseOptions);
}
export type UpdateBookingMutationHookResult = ReturnType<
  typeof useUpdateBookingMutation
>;
export type UpdateBookingMutationResult = ApolloReactCommon.MutationResult<
  UpdateBookingMutation
>;
export type UpdateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBookingMutation,
  UpdateBookingMutationVariables
>;
export const CreateBookingDocument = gql`
  mutation createBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      ...Booking
    }
  }
  ${BookingFragmentDoc}
`;
export type CreateBookingMutationFn = ApolloReactCommon.MutationFunction<
  CreateBookingMutation,
  CreateBookingMutationVariables
>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateBookingMutation,
    CreateBookingMutationVariables
  >(CreateBookingDocument, baseOptions);
}
export type CreateBookingMutationHookResult = ReturnType<
  typeof useCreateBookingMutation
>;
export type CreateBookingMutationResult = ApolloReactCommon.MutationResult<
  CreateBookingMutation
>;
export type CreateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBookingMutation,
  CreateBookingMutationVariables
>;
export const DeleteBookingDocument = gql`
  mutation deleteBooking($id: ID!) {
    deleteBooking(id: $id)
  }
`;
export type DeleteBookingMutationFn = ApolloReactCommon.MutationFunction<
  DeleteBookingMutation,
  DeleteBookingMutationVariables
>;

/**
 * __useDeleteBookingMutation__
 *
 * To run a mutation, you first call `useDeleteBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingMutation, { data, loading, error }] = useDeleteBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteBookingMutation,
    DeleteBookingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteBookingMutation,
    DeleteBookingMutationVariables
  >(DeleteBookingDocument, baseOptions);
}
export type DeleteBookingMutationHookResult = ReturnType<
  typeof useDeleteBookingMutation
>;
export type DeleteBookingMutationResult = ApolloReactCommon.MutationResult<
  DeleteBookingMutation
>;
export type DeleteBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteBookingMutation,
  DeleteBookingMutationVariables
>;
export const CardsDocument = gql`
  query cards {
    cards {
      ...Card
    }
  }
  ${CardFragmentDoc}
`;

/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCardsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CardsQuery,
    CardsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CardsQuery, CardsQueryVariables>(
    CardsDocument,
    baseOptions
  );
}
export function useCardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CardsQuery,
    CardsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CardsQuery, CardsQueryVariables>(
    CardsDocument,
    baseOptions
  );
}
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>;
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>;
export type CardsQueryResult = ApolloReactCommon.QueryResult<
  CardsQuery,
  CardsQueryVariables
>;
export const CreateCardDocument = gql`
  mutation createCard($input: CreateCardInput!) {
    createCard(input: $input) {
      ...Card
    }
  }
  ${CardFragmentDoc}
`;
export type CreateCardMutationFn = ApolloReactCommon.MutationFunction<
  CreateCardMutation,
  CreateCardMutationVariables
>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCardMutation,
    CreateCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCardMutation,
    CreateCardMutationVariables
  >(CreateCardDocument, baseOptions);
}
export type CreateCardMutationHookResult = ReturnType<
  typeof useCreateCardMutation
>;
export type CreateCardMutationResult = ApolloReactCommon.MutationResult<
  CreateCardMutation
>;
export type CreateCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCardMutation,
  CreateCardMutationVariables
>;
export const DeleteCardDocument = gql`
  mutation deleteCard($id: ID!) {
    deleteCard(id: $id)
  }
`;
export type DeleteCardMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCardMutation,
  DeleteCardMutationVariables
>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCardMutation,
    DeleteCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCardMutation,
    DeleteCardMutationVariables
  >(DeleteCardDocument, baseOptions);
}
export type DeleteCardMutationHookResult = ReturnType<
  typeof useDeleteCardMutation
>;
export type DeleteCardMutationResult = ApolloReactCommon.MutationResult<
  DeleteCardMutation
>;
export type DeleteCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCardMutation,
  DeleteCardMutationVariables
>;
export const ColorDocument = gql`
  query color($id: ID!) {
    color(id: $id) {
      ...Color
    }
  }
  ${ColorFragmentDoc}
`;

/**
 * __useColorQuery__
 *
 * To run a query within a React component, call `useColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useColorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useColorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ColorQuery,
    ColorQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ColorQuery, ColorQueryVariables>(
    ColorDocument,
    baseOptions
  );
}
export function useColorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ColorQuery,
    ColorQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ColorQuery, ColorQueryVariables>(
    ColorDocument,
    baseOptions
  );
}
export type ColorQueryHookResult = ReturnType<typeof useColorQuery>;
export type ColorLazyQueryHookResult = ReturnType<typeof useColorLazyQuery>;
export type ColorQueryResult = ApolloReactCommon.QueryResult<
  ColorQuery,
  ColorQueryVariables
>;
export const ColorsDocument = gql`
  query colors($input: SearchColorInput!) {
    colors(input: $input) {
      ...Color
    }
  }
  ${ColorFragmentDoc}
`;

/**
 * __useColorsQuery__
 *
 * To run a query within a React component, call `useColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useColorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ColorsQuery,
    ColorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ColorsQuery, ColorsQueryVariables>(
    ColorsDocument,
    baseOptions
  );
}
export function useColorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ColorsQuery,
    ColorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ColorsQuery, ColorsQueryVariables>(
    ColorsDocument,
    baseOptions
  );
}
export type ColorsQueryHookResult = ReturnType<typeof useColorsQuery>;
export type ColorsLazyQueryHookResult = ReturnType<typeof useColorsLazyQuery>;
export type ColorsQueryResult = ApolloReactCommon.QueryResult<
  ColorsQuery,
  ColorsQueryVariables
>;
export const UpdateColorDocument = gql`
  mutation updateColor($id: ID!, $input: UpdateColorInput!) {
    updateColor(id: $id, input: $input) {
      ...Color
    }
  }
  ${ColorFragmentDoc}
`;
export type UpdateColorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateColorMutation,
  UpdateColorMutationVariables
>;

/**
 * __useUpdateColorMutation__
 *
 * To run a mutation, you first call `useUpdateColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColorMutation, { data, loading, error }] = useUpdateColorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateColorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateColorMutation,
    UpdateColorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateColorMutation,
    UpdateColorMutationVariables
  >(UpdateColorDocument, baseOptions);
}
export type UpdateColorMutationHookResult = ReturnType<
  typeof useUpdateColorMutation
>;
export type UpdateColorMutationResult = ApolloReactCommon.MutationResult<
  UpdateColorMutation
>;
export type UpdateColorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateColorMutation,
  UpdateColorMutationVariables
>;
export const CreateColorDocument = gql`
  mutation createColor($input: CreateColorInput!) {
    createColor(input: $input) {
      ...Color
    }
  }
  ${ColorFragmentDoc}
`;
export type CreateColorMutationFn = ApolloReactCommon.MutationFunction<
  CreateColorMutation,
  CreateColorMutationVariables
>;

/**
 * __useCreateColorMutation__
 *
 * To run a mutation, you first call `useCreateColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createColorMutation, { data, loading, error }] = useCreateColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateColorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateColorMutation,
    CreateColorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateColorMutation,
    CreateColorMutationVariables
  >(CreateColorDocument, baseOptions);
}
export type CreateColorMutationHookResult = ReturnType<
  typeof useCreateColorMutation
>;
export type CreateColorMutationResult = ApolloReactCommon.MutationResult<
  CreateColorMutation
>;
export type CreateColorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateColorMutation,
  CreateColorMutationVariables
>;
export const DeleteColorDocument = gql`
  mutation deleteColor($id: ID!) {
    deleteColor(id: $id)
  }
`;
export type DeleteColorMutationFn = ApolloReactCommon.MutationFunction<
  DeleteColorMutation,
  DeleteColorMutationVariables
>;

/**
 * __useDeleteColorMutation__
 *
 * To run a mutation, you first call `useDeleteColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteColorMutation, { data, loading, error }] = useDeleteColorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteColorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteColorMutation,
    DeleteColorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteColorMutation,
    DeleteColorMutationVariables
  >(DeleteColorDocument, baseOptions);
}
export type DeleteColorMutationHookResult = ReturnType<
  typeof useDeleteColorMutation
>;
export type DeleteColorMutationResult = ApolloReactCommon.MutationResult<
  DeleteColorMutation
>;
export type DeleteColorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteColorMutation,
  DeleteColorMutationVariables
>;
export const ColorCategoryDocument = gql`
  query colorCategory($id: ID!) {
    colorCategory(id: $id) {
      ...ColorCategory
    }
  }
  ${ColorCategoryFragmentDoc}
`;

/**
 * __useColorCategoryQuery__
 *
 * To run a query within a React component, call `useColorCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useColorCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColorCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useColorCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ColorCategoryQuery,
    ColorCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ColorCategoryQuery,
    ColorCategoryQueryVariables
  >(ColorCategoryDocument, baseOptions);
}
export function useColorCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ColorCategoryQuery,
    ColorCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ColorCategoryQuery,
    ColorCategoryQueryVariables
  >(ColorCategoryDocument, baseOptions);
}
export type ColorCategoryQueryHookResult = ReturnType<
  typeof useColorCategoryQuery
>;
export type ColorCategoryLazyQueryHookResult = ReturnType<
  typeof useColorCategoryLazyQuery
>;
export type ColorCategoryQueryResult = ApolloReactCommon.QueryResult<
  ColorCategoryQuery,
  ColorCategoryQueryVariables
>;
export const ColorCategoriesDocument = gql`
  query colorCategories($input: SearchColorCategoryInput!) {
    colorCategories(input: $input) {
      ...ColorCategory
    }
  }
  ${ColorCategoryFragmentDoc}
`;

/**
 * __useColorCategoriesQuery__
 *
 * To run a query within a React component, call `useColorCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useColorCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColorCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useColorCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ColorCategoriesQuery,
    ColorCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ColorCategoriesQuery,
    ColorCategoriesQueryVariables
  >(ColorCategoriesDocument, baseOptions);
}
export function useColorCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ColorCategoriesQuery,
    ColorCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ColorCategoriesQuery,
    ColorCategoriesQueryVariables
  >(ColorCategoriesDocument, baseOptions);
}
export type ColorCategoriesQueryHookResult = ReturnType<
  typeof useColorCategoriesQuery
>;
export type ColorCategoriesLazyQueryHookResult = ReturnType<
  typeof useColorCategoriesLazyQuery
>;
export type ColorCategoriesQueryResult = ApolloReactCommon.QueryResult<
  ColorCategoriesQuery,
  ColorCategoriesQueryVariables
>;
export const UpdateColorCategoryDocument = gql`
  mutation updateColorCategory($id: ID!, $input: UpdateColorCategoryInput!) {
    updateColorCategory(id: $id, input: $input) {
      ...ColorCategory
    }
  }
  ${ColorCategoryFragmentDoc}
`;
export type UpdateColorCategoryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateColorCategoryMutation,
  UpdateColorCategoryMutationVariables
>;

/**
 * __useUpdateColorCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateColorCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateColorCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateColorCategoryMutation, { data, loading, error }] = useUpdateColorCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateColorCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateColorCategoryMutation,
    UpdateColorCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateColorCategoryMutation,
    UpdateColorCategoryMutationVariables
  >(UpdateColorCategoryDocument, baseOptions);
}
export type UpdateColorCategoryMutationHookResult = ReturnType<
  typeof useUpdateColorCategoryMutation
>;
export type UpdateColorCategoryMutationResult = ApolloReactCommon.MutationResult<
  UpdateColorCategoryMutation
>;
export type UpdateColorCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateColorCategoryMutation,
  UpdateColorCategoryMutationVariables
>;
export const CreateColorCategoryDocument = gql`
  mutation createColorCategory($input: CreateColorCategoryInput!) {
    createColorCategory(input: $input) {
      ...ColorCategory
    }
  }
  ${ColorCategoryFragmentDoc}
`;
export type CreateColorCategoryMutationFn = ApolloReactCommon.MutationFunction<
  CreateColorCategoryMutation,
  CreateColorCategoryMutationVariables
>;

/**
 * __useCreateColorCategoryMutation__
 *
 * To run a mutation, you first call `useCreateColorCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateColorCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createColorCategoryMutation, { data, loading, error }] = useCreateColorCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateColorCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateColorCategoryMutation,
    CreateColorCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateColorCategoryMutation,
    CreateColorCategoryMutationVariables
  >(CreateColorCategoryDocument, baseOptions);
}
export type CreateColorCategoryMutationHookResult = ReturnType<
  typeof useCreateColorCategoryMutation
>;
export type CreateColorCategoryMutationResult = ApolloReactCommon.MutationResult<
  CreateColorCategoryMutation
>;
export type CreateColorCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateColorCategoryMutation,
  CreateColorCategoryMutationVariables
>;
export const DeleteColorCategoryDocument = gql`
  mutation deleteColorCategory($id: ID!) {
    deleteColorCategory(id: $id)
  }
`;
export type DeleteColorCategoryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteColorCategoryMutation,
  DeleteColorCategoryMutationVariables
>;

/**
 * __useDeleteColorCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteColorCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteColorCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteColorCategoryMutation, { data, loading, error }] = useDeleteColorCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteColorCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteColorCategoryMutation,
    DeleteColorCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteColorCategoryMutation,
    DeleteColorCategoryMutationVariables
  >(DeleteColorCategoryDocument, baseOptions);
}
export type DeleteColorCategoryMutationHookResult = ReturnType<
  typeof useDeleteColorCategoryMutation
>;
export type DeleteColorCategoryMutationResult = ApolloReactCommon.MutationResult<
  DeleteColorCategoryMutation
>;
export type DeleteColorCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteColorCategoryMutation,
  DeleteColorCategoryMutationVariables
>;
export const CustomerDocument = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerQuery,
    CustomerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(
    CustomerDocument,
    baseOptions
  );
}
export function useCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerQuery,
    CustomerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(
    CustomerDocument,
    baseOptions
  );
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<
  typeof useCustomerLazyQuery
>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<
  CustomerQuery,
  CustomerQueryVariables
>;
export const CustomersDocument = gql`
  query customers($input: SearchCustomerInput!) {
    customers(input: $input) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomersQuery,
    CustomersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    baseOptions
  );
}
export function useCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomersQuery,
    CustomersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    baseOptions
  );
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<
  typeof useCustomersLazyQuery
>;
export type CustomersQueryResult = ApolloReactCommon.QueryResult<
  CustomersQuery,
  CustomersQueryVariables
>;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($id: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(id: $id, input: $input) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, baseOptions);
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<
  UpdateCustomerMutation
>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CreateCustomerDocument, baseOptions);
}
export type CreateCustomerMutationHookResult = ReturnType<
  typeof useCreateCustomerMutation
>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<
  CreateCustomerMutation
>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const DeleteCustomerDocument = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id)
  }
`;
export type DeleteCustomerMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables
  >(DeleteCustomerDocument, baseOptions);
}
export type DeleteCustomerMutationHookResult = ReturnType<
  typeof useDeleteCustomerMutation
>;
export type DeleteCustomerMutationResult = ApolloReactCommon.MutationResult<
  DeleteCustomerMutation
>;
export type DeleteCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;
export const SetKeyDocument = gql`
  mutation setKey($key: String!, $data: JSON) {
    setKey(key: $key, data: $data)
  }
`;
export type SetKeyMutationFn = ApolloReactCommon.MutationFunction<
  SetKeyMutation,
  SetKeyMutationVariables
>;

/**
 * __useSetKeyMutation__
 *
 * To run a mutation, you first call `useSetKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKeyMutation, { data, loading, error }] = useSetKeyMutation({
 *   variables: {
 *      key: // value for 'key'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetKeyMutation,
    SetKeyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SetKeyMutation, SetKeyMutationVariables>(
    SetKeyDocument,
    baseOptions
  );
}
export type SetKeyMutationHookResult = ReturnType<typeof useSetKeyMutation>;
export type SetKeyMutationResult = ApolloReactCommon.MutationResult<
  SetKeyMutation
>;
export type SetKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetKeyMutation,
  SetKeyMutationVariables
>;
export const GetKeyDocument = gql`
  query getKey($key: String!) {
    getKey(key: $key)
  }
`;

/**
 * __useGetKeyQuery__
 *
 * To run a query within a React component, call `useGetKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetKeyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetKeyQuery,
    GetKeyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetKeyQuery, GetKeyQueryVariables>(
    GetKeyDocument,
    baseOptions
  );
}
export function useGetKeyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetKeyQuery,
    GetKeyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetKeyQuery, GetKeyQueryVariables>(
    GetKeyDocument,
    baseOptions
  );
}
export type GetKeyQueryHookResult = ReturnType<typeof useGetKeyQuery>;
export type GetKeyLazyQueryHookResult = ReturnType<typeof useGetKeyLazyQuery>;
export type GetKeyQueryResult = ApolloReactCommon.QueryResult<
  GetKeyQuery,
  GetKeyQueryVariables
>;
export const PaymentsDocument = gql`
  query payments($input: SearchPaymentInput!) {
    payments(input: $input) {
      ...Payment
    }
  }
  ${PaymentFragmentDoc}
`;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentsQuery,
    PaymentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PaymentsQuery, PaymentsQueryVariables>(
    PaymentsDocument,
    baseOptions
  );
}
export function usePaymentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentsQuery,
    PaymentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(
    PaymentsDocument,
    baseOptions
  );
}
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<
  typeof usePaymentsLazyQuery
>;
export type PaymentsQueryResult = ApolloReactCommon.QueryResult<
  PaymentsQuery,
  PaymentsQueryVariables
>;
export const UpdatePaymentDocument = gql`
  mutation updatePayment($id: ID!, $input: UpdatePaymentInput!) {
    updatePayment(id: $id, input: $input) {
      ...Payment
    }
  }
  ${PaymentFragmentDoc}
`;
export type UpdatePaymentMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables
>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentMutation,
    UpdatePaymentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePaymentMutation,
    UpdatePaymentMutationVariables
  >(UpdatePaymentDocument, baseOptions);
}
export type UpdatePaymentMutationHookResult = ReturnType<
  typeof useUpdatePaymentMutation
>;
export type UpdatePaymentMutationResult = ApolloReactCommon.MutationResult<
  UpdatePaymentMutation
>;
export type UpdatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables
>;
export const RequestPaymentDocument = gql`
  mutation requestPayment($id: ID!) {
    requestPayment(id: $id) {
      ...Payment
    }
  }
  ${PaymentFragmentDoc}
`;
export type RequestPaymentMutationFn = ApolloReactCommon.MutationFunction<
  RequestPaymentMutation,
  RequestPaymentMutationVariables
>;

/**
 * __useRequestPaymentMutation__
 *
 * To run a mutation, you first call `useRequestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPaymentMutation, { data, loading, error }] = useRequestPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestPaymentMutation,
    RequestPaymentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestPaymentMutation,
    RequestPaymentMutationVariables
  >(RequestPaymentDocument, baseOptions);
}
export type RequestPaymentMutationHookResult = ReturnType<
  typeof useRequestPaymentMutation
>;
export type RequestPaymentMutationResult = ApolloReactCommon.MutationResult<
  RequestPaymentMutation
>;
export type RequestPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestPaymentMutation,
  RequestPaymentMutationVariables
>;
export const CreatePaymentIntentDocument = gql`
  mutation createPaymentIntent($id: ID!, $testMode: Boolean) {
    createPaymentIntent(id: $id, testMode: $testMode)
  }
`;
export type CreatePaymentIntentMutationFn = ApolloReactCommon.MutationFunction<
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutationVariables
>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      testMode: // value for 'testMode'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentIntentMutation,
    CreatePaymentIntentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreatePaymentIntentMutation,
    CreatePaymentIntentMutationVariables
  >(CreatePaymentIntentDocument, baseOptions);
}
export type CreatePaymentIntentMutationHookResult = ReturnType<
  typeof useCreatePaymentIntentMutation
>;
export type CreatePaymentIntentMutationResult = ApolloReactCommon.MutationResult<
  CreatePaymentIntentMutation
>;
export type CreatePaymentIntentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutationVariables
>;
export const ProviderDocument = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useProviderQuery__
 *
 * To run a query within a React component, call `useProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProviderQuery,
    ProviderQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProviderQuery, ProviderQueryVariables>(
    ProviderDocument,
    baseOptions
  );
}
export function useProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderQuery,
    ProviderQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ProviderQuery, ProviderQueryVariables>(
    ProviderDocument,
    baseOptions
  );
}
export type ProviderQueryHookResult = ReturnType<typeof useProviderQuery>;
export type ProviderLazyQueryHookResult = ReturnType<
  typeof useProviderLazyQuery
>;
export type ProviderQueryResult = ApolloReactCommon.QueryResult<
  ProviderQuery,
  ProviderQueryVariables
>;
export const ProvidersDocument = gql`
  query providers($input: SearchProviderInput!) {
    providers(input: $input) {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProvidersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProvidersQuery,
    ProvidersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    baseOptions
  );
}
export function useProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProvidersQuery,
    ProvidersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    baseOptions
  );
}
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<
  typeof useProvidersLazyQuery
>;
export type ProvidersQueryResult = ApolloReactCommon.QueryResult<
  ProvidersQuery,
  ProvidersQueryVariables
>;
export const UpdateProviderDocument = gql`
  mutation updateProvider($id: ID!, $input: UpdateProviderInput!) {
    updateProvider(id: $id, input: $input) {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;
export type UpdateProviderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProviderMutation,
  UpdateProviderMutationVariables
>;

/**
 * __useUpdateProviderMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMutation, { data, loading, error }] = useUpdateProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProviderMutation,
    UpdateProviderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProviderMutation,
    UpdateProviderMutationVariables
  >(UpdateProviderDocument, baseOptions);
}
export type UpdateProviderMutationHookResult = ReturnType<
  typeof useUpdateProviderMutation
>;
export type UpdateProviderMutationResult = ApolloReactCommon.MutationResult<
  UpdateProviderMutation
>;
export type UpdateProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProviderMutation,
  UpdateProviderMutationVariables
>;
export const CreateProviderDocument = gql`
  mutation createProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;
export type CreateProviderMutationFn = ApolloReactCommon.MutationFunction<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >(CreateProviderDocument, baseOptions);
}
export type CreateProviderMutationHookResult = ReturnType<
  typeof useCreateProviderMutation
>;
export type CreateProviderMutationResult = ApolloReactCommon.MutationResult<
  CreateProviderMutation
>;
export type CreateProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;
export const DeleteProviderDocument = gql`
  mutation deleteProvider($id: ID!) {
    deleteProvider(id: $id)
  }
`;
export type DeleteProviderMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProviderMutation,
  DeleteProviderMutationVariables
>;

/**
 * __useDeleteProviderMutation__
 *
 * To run a mutation, you first call `useDeleteProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProviderMutation, { data, loading, error }] = useDeleteProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProviderMutation,
    DeleteProviderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteProviderMutation,
    DeleteProviderMutationVariables
  >(DeleteProviderDocument, baseOptions);
}
export type DeleteProviderMutationHookResult = ReturnType<
  typeof useDeleteProviderMutation
>;
export type DeleteProviderMutationResult = ApolloReactCommon.MutationResult<
  DeleteProviderMutation
>;
export type DeleteProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProviderMutation,
  DeleteProviderMutationVariables
>;
export const SendAccountLinkDocument = gql`
  mutation sendAccountLink($id: ID!) {
    sendAccountLink(id: $id)
  }
`;
export type SendAccountLinkMutationFn = ApolloReactCommon.MutationFunction<
  SendAccountLinkMutation,
  SendAccountLinkMutationVariables
>;

/**
 * __useSendAccountLinkMutation__
 *
 * To run a mutation, you first call `useSendAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAccountLinkMutation, { data, loading, error }] = useSendAccountLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendAccountLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendAccountLinkMutation,
    SendAccountLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendAccountLinkMutation,
    SendAccountLinkMutationVariables
  >(SendAccountLinkDocument, baseOptions);
}
export type SendAccountLinkMutationHookResult = ReturnType<
  typeof useSendAccountLinkMutation
>;
export type SendAccountLinkMutationResult = ApolloReactCommon.MutationResult<
  SendAccountLinkMutation
>;
export type SendAccountLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendAccountLinkMutation,
  SendAccountLinkMutationVariables
>;
export const ServiceCategoryDocument = gql`
  query serviceCategory($id: ID!) {
    serviceCategory(id: $id) {
      ...ServiceCategory
    }
  }
  ${ServiceCategoryFragmentDoc}
`;

/**
 * __useServiceCategoryQuery__
 *
 * To run a query within a React component, call `useServiceCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ServiceCategoryQuery,
    ServiceCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ServiceCategoryQuery,
    ServiceCategoryQueryVariables
  >(ServiceCategoryDocument, baseOptions);
}
export function useServiceCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ServiceCategoryQuery,
    ServiceCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ServiceCategoryQuery,
    ServiceCategoryQueryVariables
  >(ServiceCategoryDocument, baseOptions);
}
export type ServiceCategoryQueryHookResult = ReturnType<
  typeof useServiceCategoryQuery
>;
export type ServiceCategoryLazyQueryHookResult = ReturnType<
  typeof useServiceCategoryLazyQuery
>;
export type ServiceCategoryQueryResult = ApolloReactCommon.QueryResult<
  ServiceCategoryQuery,
  ServiceCategoryQueryVariables
>;
export const ServiceCategoriesDocument = gql`
  query serviceCategories($input: SearchServiceCategoryInput!) {
    serviceCategories(input: $input) {
      ...ServiceCategory
    }
  }
  ${ServiceCategoryFragmentDoc}
`;

/**
 * __useServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ServiceCategoriesQuery,
    ServiceCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ServiceCategoriesQuery,
    ServiceCategoriesQueryVariables
  >(ServiceCategoriesDocument, baseOptions);
}
export function useServiceCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ServiceCategoriesQuery,
    ServiceCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ServiceCategoriesQuery,
    ServiceCategoriesQueryVariables
  >(ServiceCategoriesDocument, baseOptions);
}
export type ServiceCategoriesQueryHookResult = ReturnType<
  typeof useServiceCategoriesQuery
>;
export type ServiceCategoriesLazyQueryHookResult = ReturnType<
  typeof useServiceCategoriesLazyQuery
>;
export type ServiceCategoriesQueryResult = ApolloReactCommon.QueryResult<
  ServiceCategoriesQuery,
  ServiceCategoriesQueryVariables
>;
export const UpdateServiceCategoryDocument = gql`
  mutation updateServiceCategory(
    $id: ID!
    $input: UpdateServiceCategoryInput!
  ) {
    updateServiceCategory(id: $id, input: $input) {
      ...ServiceCategory
    }
  }
  ${ServiceCategoryFragmentDoc}
`;
export type UpdateServiceCategoryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateServiceCategoryMutation,
  UpdateServiceCategoryMutationVariables
>;

/**
 * __useUpdateServiceCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCategoryMutation, { data, loading, error }] = useUpdateServiceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateServiceCategoryMutation,
    UpdateServiceCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateServiceCategoryMutation,
    UpdateServiceCategoryMutationVariables
  >(UpdateServiceCategoryDocument, baseOptions);
}
export type UpdateServiceCategoryMutationHookResult = ReturnType<
  typeof useUpdateServiceCategoryMutation
>;
export type UpdateServiceCategoryMutationResult = ApolloReactCommon.MutationResult<
  UpdateServiceCategoryMutation
>;
export type UpdateServiceCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateServiceCategoryMutation,
  UpdateServiceCategoryMutationVariables
>;
export const CreateServiceCategoryDocument = gql`
  mutation createServiceCategory($input: CreateServiceCategoryInput!) {
    createServiceCategory(input: $input) {
      ...ServiceCategory
    }
  }
  ${ServiceCategoryFragmentDoc}
`;
export type CreateServiceCategoryMutationFn = ApolloReactCommon.MutationFunction<
  CreateServiceCategoryMutation,
  CreateServiceCategoryMutationVariables
>;

/**
 * __useCreateServiceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceCategoryMutation, { data, loading, error }] = useCreateServiceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateServiceCategoryMutation,
    CreateServiceCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateServiceCategoryMutation,
    CreateServiceCategoryMutationVariables
  >(CreateServiceCategoryDocument, baseOptions);
}
export type CreateServiceCategoryMutationHookResult = ReturnType<
  typeof useCreateServiceCategoryMutation
>;
export type CreateServiceCategoryMutationResult = ApolloReactCommon.MutationResult<
  CreateServiceCategoryMutation
>;
export type CreateServiceCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateServiceCategoryMutation,
  CreateServiceCategoryMutationVariables
>;
export const DeleteServiceCategoryDocument = gql`
  mutation deleteServiceCategory($id: ID!) {
    deleteServiceCategory(id: $id)
  }
`;
export type DeleteServiceCategoryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteServiceCategoryMutation,
  DeleteServiceCategoryMutationVariables
>;

/**
 * __useDeleteServiceCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceCategoryMutation, { data, loading, error }] = useDeleteServiceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteServiceCategoryMutation,
    DeleteServiceCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteServiceCategoryMutation,
    DeleteServiceCategoryMutationVariables
  >(DeleteServiceCategoryDocument, baseOptions);
}
export type DeleteServiceCategoryMutationHookResult = ReturnType<
  typeof useDeleteServiceCategoryMutation
>;
export type DeleteServiceCategoryMutationResult = ApolloReactCommon.MutationResult<
  DeleteServiceCategoryMutation
>;
export type DeleteServiceCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteServiceCategoryMutation,
  DeleteServiceCategoryMutationVariables
>;
export const ServiceItemDocument = gql`
  query serviceItem($id: ID!) {
    serviceItem(id: $id) {
      ...ServiceItem
    }
  }
  ${ServiceItemFragmentDoc}
`;

/**
 * __useServiceItemQuery__
 *
 * To run a query within a React component, call `useServiceItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceItemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ServiceItemQuery,
    ServiceItemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ServiceItemQuery, ServiceItemQueryVariables>(
    ServiceItemDocument,
    baseOptions
  );
}
export function useServiceItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ServiceItemQuery,
    ServiceItemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ServiceItemQuery,
    ServiceItemQueryVariables
  >(ServiceItemDocument, baseOptions);
}
export type ServiceItemQueryHookResult = ReturnType<typeof useServiceItemQuery>;
export type ServiceItemLazyQueryHookResult = ReturnType<
  typeof useServiceItemLazyQuery
>;
export type ServiceItemQueryResult = ApolloReactCommon.QueryResult<
  ServiceItemQuery,
  ServiceItemQueryVariables
>;
export const ServiceItemsDocument = gql`
  query serviceItems($input: SearchServiceItemInput!) {
    serviceItems(input: $input) {
      ...ServiceItem
    }
  }
  ${ServiceItemFragmentDoc}
`;

/**
 * __useServiceItemsQuery__
 *
 * To run a query within a React component, call `useServiceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ServiceItemsQuery,
    ServiceItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ServiceItemsQuery,
    ServiceItemsQueryVariables
  >(ServiceItemsDocument, baseOptions);
}
export function useServiceItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ServiceItemsQuery,
    ServiceItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ServiceItemsQuery,
    ServiceItemsQueryVariables
  >(ServiceItemsDocument, baseOptions);
}
export type ServiceItemsQueryHookResult = ReturnType<
  typeof useServiceItemsQuery
>;
export type ServiceItemsLazyQueryHookResult = ReturnType<
  typeof useServiceItemsLazyQuery
>;
export type ServiceItemsQueryResult = ApolloReactCommon.QueryResult<
  ServiceItemsQuery,
  ServiceItemsQueryVariables
>;
export const UpdateServiceItemDocument = gql`
  mutation updateServiceItem($id: ID!, $input: UpdateServiceItemInput!) {
    updateServiceItem(id: $id, input: $input) {
      ...ServiceItem
    }
  }
  ${ServiceItemFragmentDoc}
`;
export type UpdateServiceItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateServiceItemMutation,
  UpdateServiceItemMutationVariables
>;

/**
 * __useUpdateServiceItemMutation__
 *
 * To run a mutation, you first call `useUpdateServiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceItemMutation, { data, loading, error }] = useUpdateServiceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateServiceItemMutation,
    UpdateServiceItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateServiceItemMutation,
    UpdateServiceItemMutationVariables
  >(UpdateServiceItemDocument, baseOptions);
}
export type UpdateServiceItemMutationHookResult = ReturnType<
  typeof useUpdateServiceItemMutation
>;
export type UpdateServiceItemMutationResult = ApolloReactCommon.MutationResult<
  UpdateServiceItemMutation
>;
export type UpdateServiceItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateServiceItemMutation,
  UpdateServiceItemMutationVariables
>;
export const CreateServiceItemDocument = gql`
  mutation createServiceItem($input: CreateServiceItemInput!) {
    createServiceItem(input: $input) {
      ...ServiceItem
    }
  }
  ${ServiceItemFragmentDoc}
`;
export type CreateServiceItemMutationFn = ApolloReactCommon.MutationFunction<
  CreateServiceItemMutation,
  CreateServiceItemMutationVariables
>;

/**
 * __useCreateServiceItemMutation__
 *
 * To run a mutation, you first call `useCreateServiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceItemMutation, { data, loading, error }] = useCreateServiceItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateServiceItemMutation,
    CreateServiceItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateServiceItemMutation,
    CreateServiceItemMutationVariables
  >(CreateServiceItemDocument, baseOptions);
}
export type CreateServiceItemMutationHookResult = ReturnType<
  typeof useCreateServiceItemMutation
>;
export type CreateServiceItemMutationResult = ApolloReactCommon.MutationResult<
  CreateServiceItemMutation
>;
export type CreateServiceItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateServiceItemMutation,
  CreateServiceItemMutationVariables
>;
export const DeleteServiceItemDocument = gql`
  mutation deleteServiceItem($id: ID!) {
    deleteServiceItem(id: $id)
  }
`;
export type DeleteServiceItemMutationFn = ApolloReactCommon.MutationFunction<
  DeleteServiceItemMutation,
  DeleteServiceItemMutationVariables
>;

/**
 * __useDeleteServiceItemMutation__
 *
 * To run a mutation, you first call `useDeleteServiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceItemMutation, { data, loading, error }] = useDeleteServiceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteServiceItemMutation,
    DeleteServiceItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteServiceItemMutation,
    DeleteServiceItemMutationVariables
  >(DeleteServiceItemDocument, baseOptions);
}
export type DeleteServiceItemMutationHookResult = ReturnType<
  typeof useDeleteServiceItemMutation
>;
export type DeleteServiceItemMutationResult = ApolloReactCommon.MutationResult<
  DeleteServiceItemMutation
>;
export type DeleteServiceItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteServiceItemMutation,
  DeleteServiceItemMutationVariables
>;
export const LoginUserDocument = gql`
  mutation loginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      accessToken
      realm
    }
  }
`;
export type LoginUserMutationFn = ApolloReactCommon.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginUserMutation,
    LoginUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    LoginUserMutation,
    LoginUserMutationVariables
  >(LoginUserDocument, baseOptions);
}
export type LoginUserMutationHookResult = ReturnType<
  typeof useLoginUserMutation
>;
export type LoginUserMutationResult = ApolloReactCommon.MutationResult<
  LoginUserMutation
>;
export type LoginUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const AuthUserDocument = gql`
  query authUser {
    authUser {
      id
      email
    }
  }
`;

/**
 * __useAuthUserQuery__
 *
 * To run a query within a React component, call `useAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AuthUserQuery,
    AuthUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AuthUserQuery, AuthUserQueryVariables>(
    AuthUserDocument,
    baseOptions
  );
}
export function useAuthUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AuthUserQuery,
    AuthUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AuthUserQuery, AuthUserQueryVariables>(
    AuthUserDocument,
    baseOptions
  );
}
export type AuthUserQueryHookResult = ReturnType<typeof useAuthUserQuery>;
export type AuthUserLazyQueryHookResult = ReturnType<
  typeof useAuthUserLazyQuery
>;
export type AuthUserQueryResult = ApolloReactCommon.QueryResult<
  AuthUserQuery,
  AuthUserQueryVariables
>;
export type AddressFragment = {
  __typename?: "Address";
  country?: Maybe<string>;
  street?: Maybe<string>;
  city?: Maybe<string>;
  zip?: Maybe<string>;
  county?: Maybe<string>;
  province?: Maybe<string>;
  state?: Maybe<string>;
};

export type AppFragment = { __typename?: "App"; bookingFeePercent: number };

export type AppQueryVariables = Exact<{ [key: string]: never }>;

export type AppQuery = {
  __typename?: "Query";
  app: { __typename?: "App" } & AppFragment;
};

export type BankAccountFragment = {
  __typename?: "BankAccount";
  ssntin?: Maybe<string>;
  businessName?: Maybe<string>;
  currency?: Maybe<string>;
  routingNumber?: Maybe<string>;
  accountNumber?: Maybe<string>;
  address?: Maybe<{ __typename?: "Address" } & AddressFragment>;
};

export type BookingFragment = {
  __typename?: "Booking";
  id: string;
  code: string;
  type: BookingType;
  status: BookingStatus;
  scheduleDate?: Maybe<any>;
  serviceItems: Array<any>;
  colors?: Maybe<Array<any>>;
  provider?: Maybe<{ __typename?: "Provider" } & ProviderFragment>;
  customer: { __typename?: "Customer" } & CustomerFragment;
  location: {
    __typename?: "Location";
    name: string;
    latitude: number;
    longitude: number;
  };
  payoutStatus?: string;
};

export type BookingDetailFragment = {
  __typename?: "Booking";
  payment?: Maybe<{ __typename?: "Payment" } & PaymentFragment>;
} & BookingFragment;

export type BookingQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BookingQuery = {
  __typename?: "Query";
  booking: { __typename?: "Booking" } & BookingDetailFragment;
};

export type BookingsQueryVariables = Exact<{
  input: SearchBookingInput;
}>;

export type BookingsQuery = {
  __typename?: "Query";
  bookings: Array<{ __typename?: "Booking" } & BookingFragment>;
};

export type UpdateBookingMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateBookingInput;
}>;

export type UpdateBookingMutation = {
  __typename?: "Mutation";
  updateBooking?: Maybe<{ __typename?: "Booking" } & BookingFragment>;
};

export type CreateBookingMutationVariables = Exact<{
  input: CreateBookingInput;
}>;

export type CreateBookingMutation = {
  __typename?: "Mutation";
  createBooking?: Maybe<{ __typename?: "Booking" } & BookingFragment>;
};

export type DeleteBookingMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteBookingMutation = {
  __typename?: "Mutation";
  deleteBooking?: Maybe<number>;
};

export type CardFragment = {
  __typename?: "Card";
  id: string;
  brand: string;
  last4: string;
};

export type CardsQueryVariables = Exact<{ [key: string]: never }>;

export type CardsQuery = {
  __typename?: "Query";
  cards: Array<{ __typename?: "Card" } & CardFragment>;
};

export type CreateCardMutationVariables = Exact<{
  input: CreateCardInput;
}>;

export type CreateCardMutation = {
  __typename?: "Mutation";
  createCard?: Maybe<{ __typename?: "Card" } & CardFragment>;
};

export type DeleteCardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCardMutation = {
  __typename?: "Mutation";
  deleteCard?: Maybe<boolean>;
};

export type ColorFragment = {
  __typename?: "Color";
  id: string;
  categories: Array<string>;
  title?: Maybe<string>;
  description?: Maybe<string>;
  sort?: Maybe<number>;
  photos: Array<{ __typename?: "Media" } & MediaFragment>;
  colorCategories: Array<
    { __typename?: "ColorCategory" } & ColorCategoryFragment
  >;
};

export type ColorQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ColorQuery = {
  __typename?: "Query";
  color?: Maybe<{ __typename?: "Color" } & ColorFragment>;
};

export type ColorsQueryVariables = Exact<{
  input: SearchColorInput;
}>;

export type ColorsQuery = {
  __typename?: "Query";
  colors?: Maybe<Array<{ __typename?: "Color" } & ColorFragment>>;
};

export type UpdateColorMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateColorInput;
}>;

export type UpdateColorMutation = {
  __typename?: "Mutation";
  updateColor?: Maybe<{ __typename?: "Color" } & ColorFragment>;
};

export type CreateColorMutationVariables = Exact<{
  input: CreateColorInput;
}>;

export type CreateColorMutation = {
  __typename?: "Mutation";
  createColor?: Maybe<{ __typename?: "Color" } & ColorFragment>;
};

export type DeleteColorMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteColorMutation = {
  __typename?: "Mutation";
  deleteColor?: Maybe<number>;
};

export type ColorCategoryFragment = {
  __typename?: "ColorCategory";
  id: string;
  title?: Maybe<string>;
  parent?: Maybe<string>;
  description?: Maybe<string>;
  sort?: Maybe<number>;
};

export type ColorCategoryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ColorCategoryQuery = {
  __typename?: "Query";
  colorCategory?: Maybe<
    { __typename?: "ColorCategory" } & ColorCategoryFragment
  >;
};

export type ColorCategoriesQueryVariables = Exact<{
  input: SearchColorCategoryInput;
}>;

export type ColorCategoriesQuery = {
  __typename?: "Query";
  colorCategories?: Maybe<
    Array<{ __typename?: "ColorCategory" } & ColorCategoryFragment>
  >;
};

export type UpdateColorCategoryMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateColorCategoryInput;
}>;

export type UpdateColorCategoryMutation = {
  __typename?: "Mutation";
  updateColorCategory?: Maybe<
    { __typename?: "ColorCategory" } & ColorCategoryFragment
  >;
};

export type CreateColorCategoryMutationVariables = Exact<{
  input: CreateColorCategoryInput;
}>;

export type CreateColorCategoryMutation = {
  __typename?: "Mutation";
  createColorCategory?: Maybe<
    { __typename?: "ColorCategory" } & ColorCategoryFragment
  >;
};

export type DeleteColorCategoryMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteColorCategoryMutation = {
  __typename?: "Mutation";
  deleteColorCategory?: Maybe<number>;
};

export type CustomerFragment = {
  __typename?: "Customer";
  id: string;
  email: string;
  status: CustomerStatus;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  gender?: Maybe<Gender>;
  birthday?: Maybe<any>;
  favouriteServiceItems: Array<string>;
  favouriteColors: Array<string>;
  avatar?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  address?: Maybe<{ __typename?: "Address" } & AddressFragment>;
};

export type CustomerQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CustomerQuery = {
  __typename?: "Query";
  customer?: Maybe<{ __typename?: "Customer" } & CustomerFragment>;
};

export type CustomersQueryVariables = Exact<{
  input: SearchCustomerInput;
}>;

export type CustomersQuery = {
  __typename?: "Query";
  customers?: Maybe<Array<{ __typename?: "Customer" } & CustomerFragment>>;
};

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  updateCustomer?: Maybe<{ __typename?: "Customer" } & CustomerFragment>;
};

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: "Mutation";
  createCustomer?: Maybe<{ __typename?: "Customer" } & CustomerFragment>;
};

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCustomerMutation = {
  __typename?: "Mutation";
  deleteCustomer?: Maybe<number>;
};

export type SetKeyMutationVariables = Exact<{
  key: Scalars["String"];
  data?: Maybe<Scalars["JSON"]>;
}>;

export type SetKeyMutation = { __typename?: "Mutation"; setKey?: Maybe<any> };

export type GetKeyQueryVariables = Exact<{
  key: Scalars["String"];
}>;

export type GetKeyQuery = { __typename?: "Query"; getKey?: Maybe<any> };

export type LocationFragment = {
  __typename?: "Location";
  name: string;
  latitude: number;
  longitude: number;
};

export type MediaFragment = {
  __typename?: "Media";
  filename?: Maybe<string>;
  title?: Maybe<string>;
  attrs?: Maybe<any>;
  createdOn?: Maybe<any>;
};

export type DiscountFragment = {
  __typename?: "Discount";
  id: string;
  user: string;
  amount: number;
  percent: number;
  type: DiscountType;
  usedOn?: Maybe<any>;
  expiredOn?: Maybe<any>;
};

export type PaymentValueFragment = {
  __typename?: "PaymentValue";
  price: number;
  vat: number;
  serviceFee: number;
  processingFee: number;
  discount: number;
  total: number;
  tip: number;
};

export type PaymentFragment = {
  __typename?: "Payment";
  id: string;
  type: PaymentType;
  status: PaymentStatus;
  createdOn: any;
  updatedOn: any;
  value: { __typename?: "PaymentValue" } & PaymentValueFragment;
  discount?: Maybe<{ __typename?: "Discount" } & DiscountFragment>;
  booking: { __typename?: "Booking" } & BookingFragment;
};

export type PaymentsQueryVariables = Exact<{
  input: SearchPaymentInput;
}>;

export type PaymentsQuery = {
  __typename?: "Query";
  payments?: Maybe<Array<{ __typename?: "Payment" } & PaymentFragment>>;
};

export type UpdatePaymentMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdatePaymentInput;
}>;

export type UpdatePaymentMutation = {
  __typename?: "Mutation";
  updatePayment?: Maybe<{ __typename?: "Payment" } & PaymentFragment>;
};

export type RequestPaymentMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RequestPaymentMutation = {
  __typename?: "Mutation";
  requestPayment?: Maybe<{ __typename?: "Payment" } & PaymentFragment>;
};

export type CreatePaymentIntentMutationVariables = Exact<{
  id: Scalars["ID"];
  testMode?: Maybe<Scalars["Boolean"]>;
}>;

export type CreatePaymentIntentMutation = {
  __typename?: "Mutation";
  createPaymentIntent?: Maybe<string>;
};

export type ProviderFragment = {
  __typename?: "Provider";
  id: string;
  email: string;
  status: ProviderStatus;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  gender?: Maybe<Gender>;
  birthday?: Maybe<any>;
  serviceTypes?: Maybe<Array<Maybe<string>>>;
  bio?: Maybe<string>;
  facebook?: Maybe<string>;
  viber?: Maybe<string>;
  zalo?: Maybe<string>;
  stripeId?: Maybe<string>;
  avatar?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  address?: Maybe<{ __typename?: "Address" } & AddressFragment>;
  uploads?: Maybe<Array<Maybe<{ __typename?: "Media" } & MediaFragment>>>;
  providerId?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  governmentId?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  driverLicense?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  serviceLicense?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  additionalLicense?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  additionalCertificate?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  feature?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  feature2?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  feature3?: Maybe<{ __typename?: "Media" } & MediaFragment>;
  bankAccount?: Maybe<{ __typename?: "BankAccount" } & BankAccountFragment>;
};

export type ProviderQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProviderQuery = {
  __typename?: "Query";
  provider?: Maybe<{ __typename?: "Provider" } & ProviderFragment>;
};

export type ProvidersQueryVariables = Exact<{
  input: SearchProviderInput;
}>;

export type ProvidersQuery = {
  __typename?: "Query";
  providers?: Maybe<Array<{ __typename?: "Provider" } & ProviderFragment>>;
};

export type UpdateProviderMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateProviderInput;
}>;

export type UpdateProviderMutation = {
  __typename?: "Mutation";
  updateProvider?: Maybe<{ __typename?: "Provider" } & ProviderFragment>;
};

export type CreateProviderMutationVariables = Exact<{
  input: CreateProviderInput;
}>;

export type CreateProviderMutation = {
  __typename?: "Mutation";
  createProvider?: Maybe<{ __typename?: "Provider" } & ProviderFragment>;
};

export type DeleteProviderMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteProviderMutation = {
  __typename?: "Mutation";
  deleteProvider?: Maybe<number>;
};

export type SendAccountLinkMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SendAccountLinkMutation = {
  __typename?: "Mutation";
  sendAccountLink?: Maybe<boolean>;
};

export type ServiceCategoryFragment = {
  __typename?: "ServiceCategory";
  id: string;
  title?: Maybe<string>;
  parent?: Maybe<string>;
  description?: Maybe<string>;
  sort?: Maybe<number>;
};

export type ServiceCategoryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ServiceCategoryQuery = {
  __typename?: "Query";
  serviceCategory?: Maybe<
    { __typename?: "ServiceCategory" } & ServiceCategoryFragment
  >;
};

export type ServiceCategoriesQueryVariables = Exact<{
  input: SearchServiceCategoryInput;
}>;

export type ServiceCategoriesQuery = {
  __typename?: "Query";
  serviceCategories?: Maybe<
    Array<{ __typename?: "ServiceCategory" } & ServiceCategoryFragment>
  >;
};

export type UpdateServiceCategoryMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateServiceCategoryInput;
}>;

export type UpdateServiceCategoryMutation = {
  __typename?: "Mutation";
  updateServiceCategory?: Maybe<
    { __typename?: "ServiceCategory" } & ServiceCategoryFragment
  >;
};

export type CreateServiceCategoryMutationVariables = Exact<{
  input: CreateServiceCategoryInput;
}>;

export type CreateServiceCategoryMutation = {
  __typename?: "Mutation";
  createServiceCategory?: Maybe<
    { __typename?: "ServiceCategory" } & ServiceCategoryFragment
  >;
};

export type DeleteServiceCategoryMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteServiceCategoryMutation = {
  __typename?: "Mutation";
  deleteServiceCategory?: Maybe<number>;
};

export type ServiceItemFragment = {
  __typename?: "ServiceItem";
  id: string;
  mins?: Maybe<number>;
  categories: Array<string>;
  title?: Maybe<string>;
  description?: Maybe<string>;
  unitPrice?: Maybe<number>;
  quantity?: Maybe<number>;
  sort?: Maybe<number>;
  photos: Array<{ __typename?: "Media" } & MediaFragment>;
  serviceCategories: Array<
    { __typename?: "ServiceCategory" } & ServiceCategoryFragment
  >;
};

export type ServiceItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ServiceItemQuery = {
  __typename?: "Query";
  serviceItem?: Maybe<{ __typename?: "ServiceItem" } & ServiceItemFragment>;
};

export type ServiceItemsQueryVariables = Exact<{
  input: SearchServiceItemInput;
}>;

export type ServiceItemsQuery = {
  __typename?: "Query";
  serviceItems?: Maybe<
    Array<{ __typename?: "ServiceItem" } & ServiceItemFragment>
  >;
};

export type UpdateServiceItemMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateServiceItemInput;
}>;

export type UpdateServiceItemMutation = {
  __typename?: "Mutation";
  updateServiceItem?: Maybe<
    { __typename?: "ServiceItem" } & ServiceItemFragment
  >;
};

export type CreateServiceItemMutationVariables = Exact<{
  input: CreateServiceItemInput;
}>;

export type CreateServiceItemMutation = {
  __typename?: "Mutation";
  createServiceItem?: Maybe<
    { __typename?: "ServiceItem" } & ServiceItemFragment
  >;
};

export type DeleteServiceItemMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteServiceItemMutation = {
  __typename?: "Mutation";
  deleteServiceItem?: Maybe<number>;
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserMutation = {
  __typename?: "Mutation";
  loginUser?: Maybe<{
    __typename?: "UserToken";
    accessToken?: Maybe<string>;
    realm?: Maybe<UserRealm>;
  }>;
};

export type AuthUserQueryVariables = Exact<{ [key: string]: never }>;

export type AuthUserQuery = {
  __typename?: "Query";
  authUser?: Maybe<{
    __typename?: "User";
    id?: Maybe<string>;
    email?: Maybe<string>;
  }>;
};
