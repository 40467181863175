import React, { useEffect } from 'react';
import { User, useLoginUserMutation, useAuthUserLazyQuery, UserRealm } from '../apollo';

interface AuthContextValue {
  user: User | null;
  signIn: (email: string, password: string) => Promise<any>;
  signOut: () => void;
  loading: boolean;
}

const initialValue: AuthContextValue = {
  user: null,
  signIn: async () => {},
  signOut: async () => {},
  loading: false,
};

const TOKEN_KEY = 'lxTokens';

interface Tokens {
  accessToken: string;
}

export const getLocalTokens = (): Tokens | null => {
  try {
    const localTokens = localStorage.getItem(TOKEN_KEY) as string;
    return JSON.parse(localTokens);
  } catch (err) {
    return null;
  }
};

export const setLocalTokens = (data: Tokens) => {
  return localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
};

export const removeLocalTokens = () => {
  return localStorage.removeItem(TOKEN_KEY);
}

export const AuthContext = React.createContext<AuthContextValue>(initialValue);
export const useAuth = () => React.useContext(AuthContext);
export const WithAuth: React.FC = ({ children }) => {
  const [signInMutation, signInResult] = useLoginUserMutation();
  const [authUserQuery, authUserResult] = useAuthUserLazyQuery({ notifyOnNetworkStatusChange: true });

  const signIn = (email: string, password: string) => {
    return signInMutation({
      variables: {
        input: {
          email, password
        }
      }
    });
  };

  const checkAuth = () => {
    authUserResult.called ? authUserResult.refetch() : authUserQuery();
  };

  const signOut = () => {
    removeLocalTokens();
    checkAuth();
  };

  useEffect(() => {
    const tokens = getLocalTokens();
    if (tokens && tokens.accessToken) {
      checkAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loginUser = signInResult.data?.loginUser;
    if (loginUser && loginUser.realm === UserRealm.User && loginUser.accessToken) {
      setLocalTokens({ accessToken: loginUser.accessToken });
      checkAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInResult.data]);

  const value: AuthContextValue = {
    user: authUserResult.data?.authUser as User,
    signIn,
    signOut,
    loading: signInResult.loading || authUserResult.loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
