import React from 'react';
import { Button, Input, Form, Select, InputNumber, message } from 'antd';
import InputUpload from '../../shared/InputUpload';
import { useUpdateServiceItemMutation, useServiceItemQuery, useServiceCategoriesQuery } from '../../apollo';
import TextArea from 'antd/lib/input/TextArea';

export default function ServiceItemEdit({ record }: { record: any }) {
  const { data: categoriesData } = useServiceCategoriesQuery({
    variables: {
      input: {}
    }
  });
  const { data } = useServiceItemQuery({
    variables: {
      id: record.id
    }
  });
  const [update, { loading: submiting }] = useUpdateServiceItemMutation();
  const [form] = Form.useForm();

  const initialValues = data ? data.serviceItem : null;
  if (!initialValues || !categoriesData) {
    return null;
  }

  const onFinish = async (values: any) => {
    values.photos = (values.photos || []).map(({ filename, title }: any) => ({ filename, title }));
    const { errors } = await update({
      variables: {
        id: record.id,
        input: values
      }
    });

    if (!errors) {
      message.success('Updated');
    }
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name="title"
        label="Title"
        required={true}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="categories"
        label="Categories"
        rules={[{ required: true, type: 'array' }]}
      >
        <Select mode="multiple" placeholder="Categories">
          {categoriesData.serviceCategories?.map((item: any) => {
            return <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
      >
        <TextArea />
      </Form.Item>

      <Form.Item
        name="mins"
        label="Mins"
        rules={[{ required: true, type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="unitPrice"
        label="Price"
        rules={[{ required: true, type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="sort"
        label="Sort"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="photos"
        label="Photos"
      >
        <InputUpload multiple />
      </Form.Item>

      <Form.Item
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Button type="primary" htmlType="submit" loading={submiting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  
}