import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Button, Space } from 'antd';
import { useCreatePaymentIntentMutation } from '../../apollo';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY_TEST!);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const StripeCardForm = function({ payment, onDone }: any) {
  const [error, setError] = useState(null);
  const [charging, setCharging] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [createPaymentIntent, { data: intentClientSecret }] = useCreatePaymentIntentMutation();
  
  useEffect(() => {
    if (intentClientSecret && stripe && elements) {
      (async () => {
        const result = await stripe.confirmCardPayment(intentClientSecret.createPaymentIntent!, {
          payment_method: {
            card: elements.getElement(CardElement)!
          }
        });
        onDone(result);
      })();
    }
    // eslint-disable-next-line
  }, [intentClientSecret]);

  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  const charge = async () => {
    setCharging(true);
    createPaymentIntent({
      variables: {
        id: payment.id,
        testMode: true
      }
    });
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <CardElement
        id="card-element"
        options={CARD_ELEMENT_OPTIONS}
        onChange={handleChange}
      />
      <div className="card-errors" role="alert">{error}</div>
      <Button disabled={charging} loading={charging} type="primary" onClick={charge}>Charge</Button>
    </Space>
  );
}

export default function StripeCard(props: any) {
  return (
    <Elements stripe={stripePromise}>
      <StripeCardForm {...props} />
    </Elements>
  );
}