import React from 'react';
import { Button, Input, Form, Select, message, InputNumber } from 'antd';
import { useServiceCategoriesQuery, useServiceCategoryQuery, useUpdateServiceCategoryMutation, useCreateServiceCategoryMutation } from '../../apollo';
import TextArea from 'antd/lib/input/TextArea';

export default function ServiceCategoryEdit({ record, onSaved }: { record: any, onSaved: (record: any) => void }) {
  const { data } = useServiceCategoryQuery({
    variables: {
      id: record.id
    },
    skip: record.id === 'draft'
  });

  // Extra data
  const { data: categoriesData } = useServiceCategoriesQuery({
    variables: {
      input: {}
    }
  });

  const [update, { loading: updating }] = useUpdateServiceCategoryMutation();
  const [create, { loading: creating }] = useCreateServiceCategoryMutation();

  const [form] = Form.useForm();
  const initialValues = record.id === 'draft' ? record : (data ? data.serviceCategory : null);
  if (!initialValues || !categoriesData) {
    return null;
  }
  const isSubmitting = creating || updating;

  const onFinish = async (values: any) => {
    const { errors } = await (() => {
      if (record.id === 'draft') {
        return create({
          variables: {
            input: values
          }
        });
      }
      return update({
        variables: {
          id: record.id,
          input: values
        }
      });
    })()

    if (!errors) {
      message.success('Updated');
      return onSaved(record);
    }

  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name="title"
        label="Title"
        required={true}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="parent"
        label="Parent"
      >
        <Select placeholder="Parent Category">
          {categoriesData.serviceCategories?.map((item: any) => {
            return <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
      >
        <TextArea />
      </Form.Item>

      <Form.Item
        name="sort"
        label="Sort"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  
}