import React, { useState } from 'react';
import { Button, Form, Select, message, Input, Row, Divider, Descriptions, Image, Card, Empty, DatePicker, Space, Col } from 'antd';
import { useBookingQuery, useProvidersQuery, useCustomersQuery, useServiceItemsQuery, useColorsQuery, BookingType, useUpdateBookingMutation, useCreateBookingMutation, ServiceItemFragment, useProviderQuery, useUpdateProviderMutation, useCreateProviderMutation, ProviderStatus } from '../../apollo';
import { BookingStatus } from '../../types';
import { pickByTypeName } from '../../shared/utils';
import { SearchOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { format, parseISO } from 'date-fns';
import Title from 'antd/lib/skeleton/Title';
import InputUpload, { MEDIA_URL } from '../../shared/InputUpload';
import moment from 'moment';
import Avatar from 'antd/lib/avatar/avatar';
import { pick } from 'lodash';

export default function ProviderEdit({ record, onSaved }: { record: any, onSaved: (record: any) => void }) {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const isDraft = record.id === 'draft';
  const { data } = useProviderQuery({
    variables: {
      id: record.id
    },
    skip: isDraft
  });

  const [update, { loading: updating }] = useUpdateProviderMutation();
  const [create, { loading: creating }] = useCreateProviderMutation();

  const [form] = Form.useForm();
  const initialValues = isDraft ? record : (data ? {
    ...data.provider,
    birthday: data.provider?.birthday ? moment(data.provider?.birthday) : undefined,
    // customer: data.booking.customer.id,
    // provider: (data.booking.provider || {}).id,
    // serviceItems: data.booking.serviceItems.map(({ id }) => id),
    // colors: (data.booking.colors || []).map(({ id }) => id)
    avatar: data.provider?.avatar ? pick(data.provider?.avatar, ['filename', 'title']) : undefined
  } : null);
  if (!initialValues) {
    return null;
  }
  const isSubmitting = creating || updating;

  const onFinish = async (values: any) => {
    // const serviceItem = serviceItemsData?.serviceItems?.find(({ id }) => id === values.serviceItems[0]);
    // values = {
    //   type: BookingType.Now,
    //   status: BookingStatus.Connecting,
    //   serviceItems: [],
    //   location: {
    //     name: 'Sample Address',
    //     longitude: 105.77448959999992, 
    //     latitude: 10.0247128,
    //   },
    //   category: serviceItem?.categories[0],
    //   ...values
    // }

    // if (values.provider && providersData) {
    //   values.provider = providersData.providers!.find(({ id }) => id === values.provider);
    // }

    // if (values.customer && customersData) {
    //   values.customer = customersData.customers!.find(({ id }) => id === values.customer);
    // }

    // if (values.serviceItems && serviceItemsData) {
    //   values.serviceItems = values.serviceItems.map((itemId: string) => {
    //     return serviceItemsData.serviceItems!.find(({ id }) => id === itemId);
    //   }).map((serviceItem: ServiceItemFragment) => ({
    //     ...serviceItem,
    //     quantity: serviceItem.quantity ?? 1,
    //   }));
    // }

    // if (values.colors && colorsData) {
    //   values.colors = values.colors.map((itemId: string) => {
    //     return colorsData.colors!.find(({ id }) => id === itemId);
    //   });
    // }

    // values = pickByTypeName(values, {
    //   Provider: ['id', 'email', 'status', 'firstName', 'lastName', 'phoneNumber', 'gender', 'birthday', 'address', 'uploads', 'bio', 'facebook', 'viber', 'zalo', 'bankAccount'],
    //   Customer: ['id', 'email', 'status', 'firstName', 'lastName', 'phoneNumber', 'gender', 'address', 'birthday'],
    //   ServiceItem: [ 'id', 'categories', 'title', 'description', 'unitPrice', 'mins', 'photos', 'quantity'],
    //   Color: [ 'id', 'categories', 'title', 'description', 'photos',],
    //   Media: ['title', 'filename'],
    //   Address: ['country', 'street', 'city', 'zip', 'county', 'province', 'state',]
    // });

    values.birthday = values.birthday ? values.birthday.toISOString() : values.birthday;
    // console.log(values);

    const { errors } = await (() => {
      if (isDraft) {
        return create({
          variables: {
            input: values
          }
        });
      }
      return update({
        variables: {
          id: record.id,
          input: values
        }
      });
    })()

    if (!errors) {
      message.success('Updated');
      return onSaved(record);
    }

  };

  const renderView = () => {
    const { firstName,
      lastName,
      email,
      avatar,
      phoneNumber,
      birthday,
      facebook,
      zalo,
      viber,
      address,
      providerId,
      governmentId,
      driverLicense,
      serviceLicense,
      additionalLicense,
      additionalCertificate,
      feature,
      feature2,
      feature3,
    } = record;
    const { street, city, state, province, county, country, zip } = address || {};
    const birthdayFormatted = birthday ? format(parseISO(birthday), 'MM/dd/yyyy') + '(MM/dd/yyyy)' : '';
    const avatarSrc = avatar && `${MEDIA_URL}/${avatar.filename}`;
    return (
      <>
        <Descriptions title="Provider Info" bordered>
          {avatarSrc && (
            <Descriptions.Item label='Provider Avatar' span={5}>
              <Avatar size={100} src={avatarSrc} />
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Provider Name">
            {[firstName, lastName].filter(Boolean).join(' ')}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{email}</Descriptions.Item>
          <Descriptions.Item label="Telephone">{phoneNumber}</Descriptions.Item>
          <Descriptions.Item label="Birthday">{birthdayFormatted}</Descriptions.Item>
          <Descriptions.Item label="Social Connect">
            {[['Facebook', facebook], ['Zalo', zalo], ['Viber', viber]].filter(a => a[1])
            .map(([title, value]) => `${title}: ${value}`).join(' / ')}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {[street, city, state, province, county, country, zip].filter(Boolean).join(', ')}
          </Descriptions.Item>
        </Descriptions>
        <Row>
          {[
            ['Provider Id', providerId],
            ['Government Id', governmentId],
            ['Driver License', driverLicense],
            ['Service License', serviceLicense],
            ['Additional License', additionalLicense],
            ['Additional Certificate', additionalCertificate],
            ['Work Feature 1', feature],
            ['Work Feature 2', feature2],
            ['Work feature 3', feature3],
          ]
          // .filter(a => a[1] && a[1].filename)
          .map((a, i) => {
            let src;
            if (a[1] && a[1].filename) {
              src = `${MEDIA_URL}/${a[1].filename}`;
            }
            return (
              <Card key={i.toString()} title={a[0]} bordered={false} style={{ display: 'inline-block' }}>
                {src &&
                  <Image
                    width={150}
                    height={150}
                    src={src}
                  />
                }
                {!src && <div style={{ width: 150, height: 150 }}><Empty/></div>}
              </Card>
            );
          })}
        </Row>
      </>
    );
  }
  
  const renderEdit = () => {
    return (
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          name="avatar"
          label="Avatar"
        >
          <InputUpload />
        </Form.Item>
      
        <Form.Item
          name="firstName"
          label="First Name"
          required={true}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          required={true}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="birthday"
          label="Birthday"
          rules={[{ type: 'object' }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          required={isDraft}
          rules={[{ required: isDraft }]}
          extra={isDraft ? '' : 'If want to change password'}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label="Telephone"
        >
          <Input />
        </Form.Item>

        <Form.Item label="Address">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={['address', 'street']} noStyle>
                <Input placeholder="Street" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={['address', 'city']} noStyle>
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['address', 'zip']} noStyle>
                <Input placeholder="Zip" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={['address', 'county']} noStyle>
                <Input placeholder="County" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['address', 'state']} noStyle>
                <Input placeholder="State" />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form.Item>

        <Form.Item
          name="status"
          label="Status"
        >
          <Select>
            {Object.entries(ProviderStatus).map(([k, v], i) => (
              <Select.Option key={i.toString()} value={v}>{k}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }

  return (
    <>
      <Row justify="space-between" align="middle">
        <Text strong>{' '}</Text>
        <Button
          icon={<SearchOutlined />}
          onClick={() => setMode(mode === 'view' ? 'edit' : 'view')}
        >{mode ===  'view' ? 'Switch to Edit Mode' : 'Switch to View Mode'}</Button>
      </Row>
      <Divider />
      {mode === 'view' && renderView()}
      {mode === 'edit' && renderEdit()}
    </>
  );
  
}