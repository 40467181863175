
import React, { useState } from 'react';
import { Form, Input, Button, Layout, Typography, Alert } from 'antd';
import { useAuth } from '../../hooks/auth';

const { Content } = Layout;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function Login() {
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const onFinish = async (values: any) => {
    setErrorMessage('');
    const result = await auth.signIn(values.email, values.password);
    if (!result?.data?.loginUser) {
      setErrorMessage('Invalid credentials please try again');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <Layout>
      <Content style={{ display: 'flex', justifyContent: 'center', background: 'white' }}>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            width: 300
          }}
        >
          <Typography.Title level={2} style={{ textAlign: 'center', padding: 15 }}>LuxPro Admin</Typography.Title>
          <Form.Item
            label="Username"
            name="email"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          {errorMessage && <Alert showIcon message={errorMessage} type="error" style={{ marginBottom: 15 }} />}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={auth.loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>

  );
}