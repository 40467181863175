import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";

import { ApolloProvider } from "react-apollo";

import { setContext } from 'apollo-link-context';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

import 'antd/dist/antd.css';
import { InMemoryCache, ApolloClient } from 'apollo-boost';
import { WithAuth, getLocalTokens } from './hooks/auth';


const GRAPHQL_SERVER = process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:3000/graphql';

const getAccessToken = () => {
  const tokens = getLocalTokens();
  return tokens ? `Bearer ${tokens.accessToken}` : null;
};

const middlewareLink = setContext((_, { headers }) => {
  const authorization = getAccessToken();
  return {
    headers: {
      ...headers,
      ...(authorization ? { authorization } : {})
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
  if (response) response.errors = undefined;
});

const httpLink = createUploadLink({ uri: GRAPHQL_SERVER });

const link = from([middlewareLink, errorLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <WithAuth>
      <App />
    </WithAuth>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
