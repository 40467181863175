import React, { useEffect } from 'react';
import { Modal, Table, Tag, Space } from 'antd';
import { useBookingLazyQuery } from '../../apollo';
import StripeCard from '../../shared/StripeCard';

export default function PaymentModal({ record, onDone }: any) {
  const isVisible = Boolean(record);
  const [query, { data, refetch }] = useBookingLazyQuery({
    fetchPolicy: 'no-cache'
  });
  
  useEffect(() => {
    if (record) {
      query({
        variables: {
          id: record.id
        }
      });
    }
  }, [record, query]);

  const ok = () => onDone();

  const cancel = () => onDone();

  const charged = () => refetch();
  
  const status = data?.booking.payment?.status;

  const dataSource = () => {
    const value = data?.booking.payment?.value;
    if (!value) {
      return [];
    }
    const { price, tip, serviceFee, vat, discount, total } = value;
    return [
      {
        key: 'price',
        description: 'Price',
        amount: price
      },
      {
        key: 'tip',
        description: 'Tip',
        amount: tip
      },
      {
        key: 'serviceFee',
        description: 'Service Fee',
        amount: serviceFee
      },
      {
        key: 'vat',
        description: 'VAT',
        amount: vat
      },
      {
        key: 'discount',
        description: 'Discount',
        amount: discount
      },
      {
        key: 'total',
        description: 'Total',
        amount: total
      },
    ];
  };
  
  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  return (
    <Modal
      title="Payment"
      visible={isVisible}
      onOk={ok}
      onCancel={cancel}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Table pagination={false} dataSource={dataSource()} columns={columns} />
        <div>Status: <Tag color="success">{status}</Tag></div>
        {data &&
          <StripeCard payment={data.booking.payment} onDone={charged} />
        }
      </Space>
    </Modal>
  );
}