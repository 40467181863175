import React, { useState, useEffect, useCallback } from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { gql } from 'apollo-boost';
import { useApolloClient } from 'react-apollo';

export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL || 'http://localhost:3000/file';

const UPLOAD_FILE = gql`
  mutation($file: Upload!) {
    singleUpload(file: $file) {
      filename
    }
  }`;

export default function (props: any) {
  const { value, onChange, multiple } = props;
  const client = useApolloClient();
 
  const getInitialFileList = (data: any) => {
    return data
      ? (Array.isArray(data) ? data : [data]).map(({ filename }) => {
        return {
          uid: filename,
          name: filename,
          status: 'done',
          url: `${MEDIA_URL}/${filename}`,
          filename: filename,
        };
      })
      : [];
  };

  const [fileList, setFileList] = useState<any>(getInitialFileList(value));

  const setValue = useCallback((fileList: any) => {
    const items = fileList.filter((item: any) => item.filename).map(({ filename }: any) => ({ filename }));
    return onChange(multiple ? items : items[0]);
  }, [multiple, onChange]);

  useEffect(() => {
    const uploadTasks: Array<Promise<void>> = [];
    const nextFileList = fileList.slice();

    fileList.forEach((file: any, index: number) => {
      if (file.status === 'waiting') {
        uploadTasks.push((async () => {
          const result = await client.mutate({
            mutation: UPLOAD_FILE,
            variables: {
              file: file.originFileObj
            },
            fetchPolicy: 'no-cache',
          });
          if (result.data) {
            Object.assign(nextFileList[index], {
              status: 'done',
              filename: result.data.singleUpload.filename
            });
            setFileList(nextFileList);
            setValue(nextFileList);
          }
        })());
      }
    });

    Promise.all(uploadTasks);
  }, [fileList, client, setValue]);

  const handleChange = (info: any) => {
    setValue(info.fileList);

    const nextFileList = info.fileList.map((file: any) => {
      if (file.status) {
        return file;
      }
      return {
        uid: file.uid,
        status: 'waiting',
        name: file.name,
        originFileObj: file.originFileObj,
      }
    });
    setFileList(nextFileList);
  }

  const uploadProps: any = {
    beforeUpload: () => false,
    onChange: handleChange,
    listType: 'picture',
    fileList,
    multiple,
  };

  return (
    <Upload {...uploadProps}>
      <Button>
        <UploadOutlined /> Click to upload
      </Button>
    </Upload>
  );
}