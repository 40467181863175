import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  ssntin?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  currency?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
};

/** Booking */
export type Booking = {
  __typename?: 'Booking';
  id: Scalars['ID'];
  /**
   * NOW: process immediately
   * SCHEDULE: process on sheduled date
   */
  type: BookingType;
  /**
   * CONNECTING: Seeking for provider
   * SCHEDULED: Schedule confirmed only for BookingType = Schedule
   * WAITING: Waiting for provider come
   * PROCESSING: Service processing
   * COMPLETED: Service completed
   * FAILED: Booking failed, can happen on any steps
   */
  status: BookingStatus;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<Provider>;
  customer: Customer;
  serviceItems: Array<ServiceItem>;
  payment?: Maybe<Payment>;
  location: Location;
};

export enum BookingStatus {
  Connecting = 'CONNECTING',
  Scheduled = 'SCHEDULED',
  Waiting = 'WAITING',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export enum BookingType {
  Now = 'NOW',
  Schedule = 'SCHEDULE'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CreateBookingInput = {
  type?: Maybe<BookingType>;
  status?: Maybe<BookingStatus>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<Scalars['ID']>;
  customer?: Maybe<Scalars['ID']>;
  serviceItems?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateCustomerInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateProviderInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateServiceCategoryInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateServiceItemInput = {
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  mins?: Maybe<Scalars['Int']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: CustomerStatus;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  birthday?: Maybe<Scalars['DateTime']>;
};

export enum CustomerStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED'
}


export type DiscountModel = {
  __typename?: 'DiscountModel';
  id: Scalars['ID'];
  user: Scalars['ID'];
  amount: Scalars['Float'];
  percent: Scalars['Float'];
  type: DiscountType;
  usedOn?: Maybe<Scalars['DateTime']>;
  expiredOn?: Maybe<Scalars['DateTime']>;
};

export enum DiscountType {
  Promotion = 'PROMOTION',
  Referrer = 'REFERRER',
  Referred = 'REFERRED',
  Voucher = 'VOUCHER'
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER'
}


export type Location = {
  __typename?: 'Location';
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type LoginUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  filename?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  attrs?: Maybe<Scalars['JSON']>;
  createdOn?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  root?: Maybe<Scalars['String']>;
  createUser?: Maybe<User>;
  loginUser?: Maybe<UserToken>;
  createProvider?: Maybe<Provider>;
  loginProvider?: Maybe<UserToken>;
  createCustomer?: Maybe<Customer>;
  loginCustomer?: Maybe<UserToken>;
  createServiceCategory?: Maybe<ServiceCategory>;
  deleteServiceCategory?: Maybe<Scalars['Int']>;
  createServiceItem?: Maybe<ServiceItem>;
  deleteServiceItem?: Maybe<Scalars['Int']>;
  updatePayment?: Maybe<Payment>;
  createBooking?: Maybe<Booking>;
  deleteBooking?: Maybe<Scalars['Int']>;
  updateBooking?: Maybe<Booking>;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationLoginUserArgs = {
  input: LoginUserInput;
};


export type MutationCreateProviderArgs = {
  input: CreateProviderInput;
};


export type MutationLoginProviderArgs = {
  input: LoginUserInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationLoginCustomerArgs = {
  input: LoginUserInput;
};


export type MutationCreateServiceCategoryArgs = {
  input: CreateServiceCategoryInput;
};


export type MutationDeleteServiceCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateServiceItemArgs = {
  input: CreateServiceItemInput;
};


export type MutationDeleteServiceItemArgs = {
  id: Scalars['ID'];
};


export type MutationUpdatePaymentArgs = {
  id: Scalars['ID'];
  input: UpdatePaymentInput;
};


export type MutationCreateBookingArgs = {
  input: CreateBookingInput;
};


export type MutationDeleteBookingArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateBookingArgs = {
  id: Scalars['ID'];
  input: UpdateBookingInput;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  status: PaymentStatus;
  value: PaymentValueModel;
  discount?: Maybe<DiscountModel>;
  booking: Scalars['ID'];
};

export enum PaymentStatus {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Completed = 'COMPLETED'
}

export type PaymentValueModel = {
  __typename?: 'PaymentValueModel';
  price: Scalars['Float'];
  vat: Scalars['Float'];
  serviceFee: Scalars['Float'];
  processingFee: Scalars['Float'];
  discount: Scalars['Float'];
  total: Scalars['Float'];
};

export type Provider = {
  __typename?: 'Provider';
  id: Scalars['ID'];
  email: Scalars['String'];
  status: ProviderStatus;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  birthday?: Maybe<Scalars['DateTime']>;
  serviceTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Address>;
  uploads?: Maybe<Array<Maybe<Media>>>;
  bio?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  viber?: Maybe<Scalars['String']>;
  zalo?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccount>;
};

export enum ProviderStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Reviewing = 'REVIEWING'
}

export type Query = {
  __typename?: 'Query';
  root?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  authUser?: Maybe<User>;
  provider?: Maybe<Provider>;
  authProvider?: Maybe<Provider>;
  customer?: Maybe<Customer>;
  authCustomer?: Maybe<Customer>;
  serviceCategory?: Maybe<ServiceCategory>;
  serviceCategories?: Maybe<Array<Maybe<ServiceCategory>>>;
  serviceItem?: Maybe<ServiceItem>;
  serviceItems?: Maybe<Array<Maybe<ServiceItem>>>;
  payment?: Maybe<Payment>;
  payments?: Maybe<Array<Payment>>;
  booking: Booking;
  bookings: Array<Booking>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryProviderArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryServiceCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryServiceCategoriesArgs = {
  input?: Maybe<SearchServiceCategoryInput>;
};


export type QueryServiceItemArgs = {
  id: Scalars['ID'];
};


export type QueryServiceItemsArgs = {
  input?: Maybe<SearchServiceItemInput>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentsArgs = {
  input?: Maybe<SearchPaymentInput>;
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingsArgs = {
  input?: Maybe<SearchBookingInput>;
};

export type SearchBookingInput = {
  keyword?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type SearchPaymentInput = {
  keyword?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type SearchServiceCategoryInput = {
  keyword?: Maybe<Scalars['String']>;
};

export type SearchServiceItemInput = {
  keyword?: Maybe<Scalars['String']>;
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  serviceItems?: Maybe<Array<Maybe<ServiceItem>>>;
};

export type ServiceItem = {
  __typename?: 'ServiceItem';
  id: Scalars['ID'];
  categories?: Maybe<Array<Scalars['ID']>>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  mins?: Maybe<Scalars['Int']>;
  serviceCategories: Array<ServiceCategory>;
};

export type Subscription = {
  __typename?: 'Subscription';
  root?: Maybe<Scalars['String']>;
  bookingUpdated?: Maybe<Booking>;
};


export type SubscriptionBookingUpdatedArgs = {
  id: Scalars['ID'];
};

export type UpdateBookingInput = {
  type?: Maybe<BookingType>;
  status?: Maybe<BookingStatus>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<Scalars['ID']>;
  customer?: Maybe<Scalars['ID']>;
  serviceItems?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdatePaymentInput = {
  status?: Maybe<PaymentStatus>;
};


export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type UserToken = {
  __typename?: 'UserToken';
  accessToken?: Maybe<Scalars['String']>;
};


