import React from 'react';
import { Button, Input, Form, Select, message, InputNumber } from 'antd';
import { useServiceCategoriesQuery, useServiceCategoryQuery, useUpdateServiceCategoryMutation, useCreateServiceCategoryMutation, useColorQuery, useColorCategoriesQuery, useUpdateColorMutation, useCreateColorMutation } from '../../apollo';
import TextArea from 'antd/lib/input/TextArea';
import InputUpload from '../../shared/InputUpload';

export default function ColorEdit({ record, onSaved }: { record: any, onSaved: (record: any) => void }) {
  const { data } = useColorQuery({
    variables: {
      id: record.id
    },
    skip: record.id === 'draft'
  });

  // Extra data
  const { data: categoriesData } = useColorCategoriesQuery({
    variables: {
      input: {}
    }
  });

  const [update, { loading: updating }] = useUpdateColorMutation();
  const [create, { loading: creating }] = useCreateColorMutation();

  const [form] = Form.useForm();
  const initialValues = record.id === 'draft' ? record : (data ? data.color : null);
  if (!initialValues || !categoriesData) {
    return null;
  }
  const isSubmitting = creating || updating;

  const onFinish = async (values: any) => {
    const { errors } = await (() => {
      values.photos = (values.photos || []).map(({ filename, title }: any) => ({ filename, title }));
      if (record.id === 'draft') {
        return create({
          variables: {
            input: values
          }
        });
      }
      return update({
        variables: {
          id: record.id,
          input: values
        }
      });
    })()

    if (!errors) {
      message.success('Updated');
      return onSaved(record);
    }

  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name="title"
        label="Title"
        required={true}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="categories"
        label="Categories"
      >
        <Select mode="multiple" placeholder="Collections">
          {categoriesData.colorCategories?.map((item: any) => {
            return <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
      >
        <TextArea />
      </Form.Item>

      <Form.Item
        name="sort"
        label="Sort"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="photos"
        label="Photos"
      >
        <InputUpload multiple />
      </Form.Item>

      <Form.Item
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  
}