import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import './App.css';
import { Layout, Divider, Typography, Button, Row, Col, PageHeader, Menu, Breadcrumb } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined, LogoutOutlined, FileDoneOutlined, SettingOutlined } from '@ant-design/icons';
import ProviderList from '../../components/ProviderList';
import ServiceItemList from '../../components/ServiceItemList';
import ServiceCategoryList from '../../components/ServiceCategoryList';
import ColorCategoryList from '../../components/ColorCategoryList';
import ColorList from '../../components/ColorList';
import BookingList from '../../components/BookingList';
import CustomerList from '../../components/CustomerList';
import SubMenu from 'antd/lib/menu/SubMenu';
import AppFeeSettings from '../../components/AppFeeSettings';
import NotificationSettings from '../../components/AppFeeSettings/NotificationSettings';
import { useAuth } from '../../hooks/auth';
import Login from '../../components/Login';
import BookingSettings from '../../components/AppFeeSettings/BookingSettings';
import EmailTemplatesSettings from '../../components/AppFeeSettings/EmailTemplatesSettings';
import PayoutBookingList from '../../components/PayoutBookingList';

const routes = [
  { path: '/bookings', title: 'Bookings' },
  { path: '/payout-bookings', title: 'Payout Bookings' },
  { path: '/providers', title: 'Providers' },
  { path: '/customers', title: 'Customers' },
  { path: '/color-categories', title: 'Color Collections' },
  { path: '/colors', title: 'Colors' },
  { path: '/service-categories', title: 'Service Categories' },
  { path: '/service-items', title: 'Service Items' },
];

const App: React.FC = () => {
  const auth = useAuth();
  if (!auth.user) {
    return (
      <Login />
    );
  }
  return (
    <Router>
      <Layout>
        <Layout.Header className="header">
          <div className="logo" style={{
            width: '120px',
            height: '31px',
            background: 'rgba(255, 255, 255, 0.2)',
            margin: '16px 28px 16px 0',
            float: 'left',
          }} />
          {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
            <Menu.Item key="1">nav 1</Menu.Item>
            <Menu.Item key="2">nav 2</Menu.Item>
            <Menu.Item key="3">nav 3</Menu.Item>
          </Menu> */}
        </Layout.Header>
        <Layout>
          <Layout.Sider width={200}>
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ height: '100%', borderRight: 0 }}
            >
              {routes.map(({ title, path }, index) => (
                <Menu.Item key={index.toString()} icon={<FileDoneOutlined />}>
                  <Link to={path}>{title}</Link>
                </Menu.Item>
              ))}
              <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
                <Menu.Item key="app-fee">
                  <Link to="/app-fee">App Fee Settings</Link>
                </Menu.Item>
                <Menu.Item key="notifictions">
                  <Link to="/notifications">Emails & Notifications</Link>
                </Menu.Item>
                <Menu.Item key="bookingSettings">
                  <Link to="/booking-settings">Booking Settings</Link>
                </Menu.Item>
                <Menu.Item key="emailTemplatesSettings">
                  <Link to="/email-templates-settings">Email Templates Settings</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item icon={<LogoutOutlined />} onClick={auth.signOut}>
                Logout
              </Menu.Item>
            </Menu>
          </Layout.Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Switch>
                <Route path="/bookings">
                  <BookingList />
                </Route>
                <Route path="/payout-bookings">
                  <PayoutBookingList />
                </Route>
                <Route path="/providers">
                  <ProviderList />
                </Route>
                <Route path="/customers">
                  <CustomerList />
                </Route>
                <Route path="/color-categories">
                  <ColorCategoryList />
                </Route>
                <Route path="/colors">
                  <ColorList />
                </Route>
                <Route path="/service-categories">
                  <ServiceCategoryList />
                </Route>
                <Route path="/service-items">
                  <ServiceItemList />
                </Route>
                <Route path="/app-fee">
                  <AppFeeSettings />
                </Route>
                <Route path="/booking-settings">
                  <BookingSettings />
                </Route>
                <Route path="/email-templates-settings">
                  <EmailTemplatesSettings />
                </Route>
                <Route path="/notifications">
                  <NotificationSettings />
                </Route>
              </Switch>
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
