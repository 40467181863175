import React from 'react';
import { Button, Input, Form, message, Card } from 'antd';
import { useGetKeyQuery, useSetKeyMutation } from '../../apollo';

export default function KeyDataItem(props: any) {
  const { k: key, title, extra, layout } = props;
  const [form] = Form.useForm();
  const { data, loading } = useGetKeyQuery({
    variables: { key }
  });

  const [save, { loading: updating }] = useSetKeyMutation();

  if (loading) {
    return null;
  }

  const initialValues = {
    [key]: data?.getKey
  };

  const isSubmitting = updating;

  const onFinish = async (values: any) => {
    const { errors } = await save({
      variables: {
        key,
        data: values[key]
      }
    });
    if (!errors) {
      message.success('Updated');
    }

  };

  if (props.render) {
    return (<Form
      form={form}
      layout={layout}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      {props.render({
        isSubmitting
      })}
    </Form>)
  }

  return (
    <Card title={title} style={{ maxWidth: '500px', marginBottom: '1rem' }}>
      <Form
        form={form}
        layout={layout}
        initialValues={initialValues}
        onFinish={onFinish}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          name={key}
          label={title}
          required={true}
          rules={[{ required: true }]}
          extra={extra}
        >
          {props.children || <Input />}
        </Form.Item>
        <Form.Item
          wrapperCol={{ offset: 12, span: 12 }}
        >
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}
