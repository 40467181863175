import React, { useState } from 'react';
import { Table, PageHeader, Button, Modal } from 'antd';
import { useProvidersQuery, useCreateServiceItemMutation, useDeleteServiceItemMutation, useCustomersQuery, useDeleteCustomerMutation } from '../../apollo';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ServiceItemEdit from '../ServiceItemEdit';
import { useHistory } from 'react-router-dom';
import CustomerEdit from '../CustomerEdit';

export default function CustomerList() {
  const history = useHistory();
  const { loading, error, data, refetch } = useCustomersQuery({
    variables: {
      input: {}
    }
  });
  const [dele] = useDeleteCustomerMutation();
  const [draft, setDraft] = useState<any>();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading...</p>;

  const records: Array<any> = (draft ? [draft] : []).concat(data?.customers || []);
  
  const add = async () => {
    if (!draft) {
      setDraft({
        id: 'draft',
      });
    }
  };

  const del = async (record: any) => {
    if (draft) {
      return setDraft(null);
    }
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Delete ${record.firstName} ${record.lastName}?`,
      onOk: async () => {
        await dele({
          variables: {
            id: record.id
          }
        });
        refetch();
      }
    });
  };

  const saved = ({ id }: any) => {
    if (id === 'draft') {
      setDraft(null);
      refetch();
    }
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: ['email'],
    },
    {
      title: 'FirstName',
      dataIndex: ['firstName'],
    },
    {
      title: 'LastName',
      dataIndex: ['lastName'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Button onClick={() => del(record)} type="primary" danger>Delete</Button>
      ),
    },
  ];
  
  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title="Customers"
        extra={[
          <Button key="1" onClick={add} type="primary" icon={<PlusOutlined />}>
            Add
          </Button>,
        ]}
      />
      <Table
        rowKey="id"
        dataSource={records}
        columns={columns}
        expandable={{
          expandedRowRender: record => <CustomerEdit record={record} onSaved={saved} />,
        }}
      />
    </>
  );
}