import { isPlainObject, pick } from 'lodash';

export function pickByTypeName(value: any, fields: { [k: string]: string[] }) {
  if (typeof value === 'undefined') {
    return value;
  }
  
  if (Array.isArray(value)) {
    for (var i = 0; i < value.length; i++) {
      value[i] = pickByTypeName(value[i], fields);
    }
    return value;
  }
  
  if (!isPlainObject(value)) {
    return value;
  }

  if (value.__typename && fields[value.__typename]) {
    value = pick(value, fields[value.__typename] || []);
  }

  return Object.keys(value).reduce<any>((prev, key) => {
    prev[key] = pickByTypeName(value[key], fields);
    return prev;
  }, {});

}

/**
 * Sort by sort field and add level field
 * @param records 
 */
export function sortCategories(records: any) {
  function getSortLevel(item: any): any {
    const parent = item.parent && records.find((r: any) => r.id === item.parent);
    return [
      ...(parent ? getSortLevel(parent) : []),
      item.sort || 1
    ];
  }
  records.forEach((record: any) => {
    record.level = getSortLevel(record);
  });
  return records.sort((a: any, b: any) => {
    const as = a.level;
    const bs = b.level;
    const l = Math.max(as.length, bs.length);
    for (let i = 0; i < l; i++) {
      if (as[i] !== bs[i]) {
        return (bs[i] || Infinity) - (as[i] || Infinity);
      }
    }
    return -1;
  });
}