import React from "react";
import { Button, Card, Form, Input, InputNumber, Row } from "antd";
import KeyDataItem from "./KeyDataItem";
import TextArea from "antd/lib/input/TextArea";

const templateTitle = {
  bookingAlertTemplate: 'Booking Alert',
  bookingStatusTemplate: 'Booking Status',
  accountLinkTemplate: 'Account Link',
  welcomeTemplate: 'Welcome',
  providerCreatedTemplate: 'Provider Created',
  providerProfileUpdatedTemplate: 'Provider Profile Updated',
  resetPasswordTemplate: 'User Reset Password',
}

type templateKey = keyof typeof templateTitle;
const templateKeys = Object.keys(templateTitle) as templateKey[];

const EmailDataItem: React.FC<{ name: templateKey }> = ({ name }) => {
  return (
    <KeyDataItem layout='vertical' k={name} render={({ isSubmitting } : { isSubmitting: boolean }) => (
        <Card title={templateTitle[name]} style={{ marginBottom: 24 }}>
        <>
          <Form.Item
            name={[name, "tokens"]}
            label={"Tokens"}
            required={true}
            rules={[{ required: true }]}
          >
            <TextArea autoSize disabled />
          </Form.Item>
          <Form.Item
            name={[name, "subject"]}
            label={"Subject"}
            required={true}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={[name, "content"]}
            label={"Content"}
            required={true}
            rules={[{ required: true }]}
          >
            <TextArea style={{ minHeight: 200 }} />
          </Form.Item>
          <Row justify='end'>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Save
            </Button>
          </Row>
        </>
    </Card>
      )}/>
  );
};

export default function EmailTemplatesSettings() {
  return (
    <>
      {templateKeys.map((name) => (
        <EmailDataItem key={name} name={name} />
      ))}
    </>
  );
}
