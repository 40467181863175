import React from 'react';
import { Button, Form, Select, message, Space, Input, InputNumber, Row, Col } from 'antd';
import { useBookingQuery, useProvidersQuery, useCustomersQuery, useServiceItemsQuery, useColorsQuery, BookingType, useUpdateBookingMutation, useCreateBookingMutation, ServiceItemFragment, useServiceCategoriesQuery } from '../../apollo';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { BookingStatus } from '../../types';
import { pickByTypeName } from '../../shared/utils';

export default function BookingEdit({ record, onSaved }: { record: any, onSaved: (record: any) => void }) {
  const isDraft = record.id === 'draft';
  const { data } = useBookingQuery({
    variables: {
      id: record.id
    },
    skip: isDraft
  });

  // Extra data
  const { data: providersData } = useProvidersQuery({
    variables: {
      input: {}
    }
  });
  const { data: customersData } = useCustomersQuery({
    variables: {
      input: {}
    }
  });
  const { data: serviceItemsData } = useServiceItemsQuery({
    variables: {
      input: {}
    }
  });
  const { data: colorsData } = useColorsQuery({
    variables: {
      input: {}
    }
  });

  const [update, { loading: updating }] = useUpdateBookingMutation();
  const [create, { loading: creating }] = useCreateBookingMutation();

  const [form] = Form.useForm();
  const initialValues = isDraft ? record : (data ? {
    ...data.booking,
    customer: data.booking.customer.id,
    provider: (data.booking.provider || {}).id,
    // serviceItems: data.booking.serviceItems.map(({ id }) => id),
    // colors: (data.booking.colors || []).map(({ id }) => id)
  } : null);
  if (!initialValues || !providersData || !customersData || !serviceItemsData || !colorsData) {
    return null;
  }
  // console.log(data?.booking.serviceItems.q);
  const isSubmitting = creating || updating;

  const onFinish = async (values: any) => {
    // const serviceItem = serviceItemsData?.serviceItems?.find(({ id }) => id === values.serviceItems[0]);
    values = {
      // type: BookingType.Now,
      status: BookingStatus.Connecting,
      // location: {
      //   name: 'Sample Address',
      //   longitude: 105.77448959999992, 
      //   latitude: 10.0247128,
      // },
      // category: serviceItem?.categories[0],
      ...values
    }

    if (values.provider && providersData) {
      values.provider = providersData.providers!.find(({ id }) => id === values.provider);
    }

    if (values.customer && customersData) {
      values.customer = customersData.customers!.find(({ id }) => id === values.customer);
    }

    if (values.serviceItems && serviceItemsData) {
      values.serviceItems = values.serviceItems.map((sItem: any) => {
        return {
          ...serviceItemsData.serviceItems!.find(({ id }) => id === sItem.id),
          ...sItem
        }
      });
    }

    // if (values.colors && colorsData) {
    //   values.colors = values.colors.map((itemId: string) => {
    //     return colorsData.colors!.find(({ id }) => id === itemId);
    //   });
    // }

    values = pickByTypeName(values, {
      Provider: ['id', 'email', 'status', 'firstName', 'lastName', 'phoneNumber', 'gender', 'birthday', 'address', 'uploads', 'bio', 'facebook', 'viber', 'zalo', 'bankAccount'],
      Customer: ['id', 'email', 'status', 'firstName', 'lastName', 'phoneNumber', 'gender', 'address', 'birthday', 'favouriteServiceItems', 'favouriteColors'],
      ServiceItem: [ 'id', 'categories', 'title', 'description', 'unitPrice', 'mins', 'photos', 'quantity'],
      Color: [ 'id', 'categories', 'title', 'description', 'photos',],
      Media: ['title', 'filename'],
      Address: ['country', 'street', 'city', 'zip', 'county', 'province', 'state',]
    });

    const { errors } = await (() => {
      if (isDraft) {
        return create({
          variables: {
            input: values
          }
        });
      }
      return update({
        variables: {
          id: record.id,
          input: values
        }
      });
    })()

    if (!errors) {
      message.success('Updated');
      return onSaved(record);
    }

  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name="status"
        label="Status"
      >
        <Select>
          <Select.Option value="CONNECTING">CONNECTING</Select.Option>
          <Select.Option value="SCHEDULED">SCHEDULED</Select.Option>
          <Select.Option value="WAITING">CONFIRMED</Select.Option>
          <Select.Option value="PROCESSING">PROCESSING</Select.Option>
          <Select.Option value="COMPLETED">SERVICED</Select.Option>
          <Select.Option value="SUCCESS">COMPLETED</Select.Option>
          <Select.Option value="FAILED">FAILED</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="payoutStatus"
        label="Payout Status"
      >
        <Select>
          <Select.Option value="PENDING">PENDING</Select.Option>
          <Select.Option value="COMPLETED">COMPLETED</Select.Option>
        </Select>
      </Form.Item>

      {!isDraft &&
        <Form.Item
          name="provider"
          label="Provider"
        >
          <Select
            placeholder="Providers"
            showSearch
            filterOption={(input, option) => {
              const keyword = option?.['data-keyword'];
              return keyword.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {providersData.providers?.map((item: any) => {
              return (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  data-keyword={[item.email, item.firstName, item.lastName].join(' ')}
                  title={item.email}>
                  {item.email} - {item.firstName} {item.lastName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      }

      <Form.Item
        name="customer"
        label="Customer"
        rules={[{ required: true, type: 'string' }]}
      >
        <Select
          showSearch
          filterOption={(input, option) => {
            const keyword = option?.['data-keyword'];
            return keyword.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          placeholder="Customers">
          {customersData.customers?.map((item: any) => {
            return (
              <Select.Option
                key={item.id}
                value={item.id}
                data-keyword={[item.email, item.firstName, item.lastName].join(' ')}
                title={item.email}>
                {item.email} - {item.firstName} {item.lastName}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      {/* <Form.Item label="Service Items" style={{ marginBottom: 0 }}>
        <Form.List name="serviceItems">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(field => (
                  <Space key={field.key} direction="vertical" style={{ width: '100%' }}>
                    <Space direction="horizontal">
                      <Form.Item
                        {...field}
                        name={[field.name, 'id']}
                        fieldKey={[field.fieldKey, 'id']}
                        rules={[{ required: true, message: 'Missing service item' }]}
                        style={{ width: 200 }}
                      >
                        <Select placeholder="Service Item">
                          {serviceItemsData.serviceItems?.map((item: any) => {
                            return <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'quantity']}
                        fieldKey={[field.fieldKey, 'quantity']}
                        rules={[{ required: true }]}
                        style={{ width: 100 }}
                      >
                        <InputNumber />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => { remove(field.name); }} style={{ marginBottom: 26 }} />
                    </Space>
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> Add Service Item
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form.Item> */}

      <Form.Item
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  
}