import React from 'react';
import { InputNumber } from 'antd';
import KeyDataItem from './KeyDataItem';

export default function AppFeeSettings() {
  return (
    <>
      <KeyDataItem title="Booking Fee" k="appFeePercent">
        <InputNumber
          min={0}
          max={100}
          formatter={value => `${value}%`}
          parser={value => value!.replace('%', '')}
        />
      </KeyDataItem>
      <KeyDataItem title="Minimum Booking Amount" k="mininumBookingAmount">
        <InputNumber
          min={0}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value!.replace(/\$\s?|(,*)/g, '')}
        />
      </KeyDataItem>
    </>
  );
}