import React from 'react';
import { Table, PageHeader, Button, Modal } from 'antd';
import { useServiceItemsQuery, useCreateServiceItemMutation, useDeleteServiceItemMutation } from '../../apollo';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ServiceItemEdit from '../ServiceItemEdit';
import { useHistory } from 'react-router-dom';

export default function ServiceItemList() {
  const history = useHistory();
  const { loading, error, data, refetch } = useServiceItemsQuery({
    variables: {
      input: {
        sort: {
          sort: -1
        }
      }
    }
  });

  const [createServiceItem] = useCreateServiceItemMutation();
  const [deleteServiceItem] = useDeleteServiceItemMutation();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading...</p>;

  const records: Array<any> = data?.serviceItems || [];
  
  const add = async () => {
    await createServiceItem({
      variables: {
        input: {
          title: 'New Service Item',
          photos: [],
        }
      }
    });
    refetch();
  };

  const del = async (record: any) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Delete ${record.title}?`,
      onOk: async () => {
        await deleteServiceItem({
          variables: {
            id: record.id
          }
        });
        refetch();
      }
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: ['title'],
    },
    {
      title: 'Sort',
      dataIndex: ['sort'],
    },
    {
      title: 'Description',
      dataIndex: ['description'],
    },
    {
      title: 'Unit Price',
      dataIndex: ['unitPrice'],
    },
    {
      title: 'Mins',
      dataIndex: ['mins'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Button onClick={() => del(record)} type="primary" danger>Delete</Button>
      ),
    },
  ];
  
  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title="Service Items"
        extra={[
          <Button key="1" onClick={add} type="primary" icon={<PlusOutlined />}>
            Add
          </Button>,
        ]}
      />
      <Table
        rowKey="id"
        dataSource={records}
        columns={columns}
        expandable={{
          expandedRowRender: record => <ServiceItemEdit record={record} />,
        }}
      />
    </>
  );
}