import React, { useState } from 'react';
import { Table, PageHeader, Button, Modal } from 'antd';
import { useColorCategoriesQuery, useDeleteColorCategoryMutation } from '../../apollo';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ColorCategoryEdit from '../ColorCategoryEdit';
import { useHistory } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { sortCategories } from '../../shared/utils';

export default function ColorCategoryList() {
  const history = useHistory();
  const { loading, error, data, refetch } = useColorCategoriesQuery({
    variables: {
      input: {
        sort: {
          sort: -1
        }
      }
    }
  });
  const [dele] = useDeleteColorCategoryMutation();
  const [draft, setDraft] = useState<any>();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading...</p>;

  const records: Array<any> = sortCategories((draft ? [draft] : []).concat(data?.colorCategories || []));
  
  const add = async () => {
    if (!draft) {
      setDraft({
        id: 'draft',
        title: 'New color collection'
      });
    }
  };

  const del = async (record: any) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Delete ${record.title}?`,
      onOk: async () => {
        await dele({
          variables: {
            id: record.id
          }
        });
        refetch();
      }
    });
  };

  const saved = ({ id }: any) => {
    if (id === 'draft') {
      setDraft(null);
      refetch();
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: ['title'],
      render: (_: any, { title, level }: any) => {
        return <Text>{`${' - -'.repeat(level.length - 1)} ${title}`}</Text>;
      }
    },
    {
      title: 'Sort',
      dataIndex: ['sort'],
    },
    {
      title: 'Description',
      dataIndex: ['description'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Button onClick={() => del(record)} type="primary" danger>Delete</Button>
      ),
    },
  ];
  
  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.goBack()}
        title="Color Collections"
        extra={[
          <Button key="1" onClick={add} type="primary" icon={<PlusOutlined />}>
            Add
          </Button>,
        ]}
      />
      <Table
        rowKey="id"
        dataSource={records}
        columns={columns}
        expandable={{
          expandedRowRender: record => <ColorCategoryEdit record={record} onSaved={saved} />,
        }}
      />
    </>
  );
}