import Text from 'antd/lib/typography/Text';
import React from 'react';
import KeyDataItem from './KeyDataItem';

export default function NotificationSettings() {
  return (
    <>
      <KeyDataItem title="Booking Notification Email" k="bookingNotificationEmails" extra="Comma-separated values" />
      <KeyDataItem title="Email on Provider Created" k="providerCreatedEmails" extra="Comma-separated values" />
    </>
  );
}